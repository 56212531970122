/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Input, Modal, Select, Checkbox } from 'antd';
import { useChangeHandler } from 'hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { sitesCleanUp } from 'state/actions/sites';
import { regexDescription, regexInput } from 'utils/regex';

import { log } from 'utils';

const { Option } = Select;

const ModalSiteForm = ({
  site,
  setSite,
  visible,
  onCancel,
  onCreate,
  isEditing,
}) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });
  const [isReqApproval, setIsReqApproval] = useState(site.isReqApproval);
  const [isDirectory, setIsDirectory] = useState(site.isDirectory);
  const [isCommercial, setIsCommercial] = useState(site.isCommercial);
  const [isAdsum, setIsAdsum] = useState(site.isAdsum);

  const { organizations, loading } = useSelector(
    (state) => ({
      organizations: state.organizations.list,
      loading: state.sites.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    return () => dispatch(sitesCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setSite);

  const onSelectOrganizationHandler = (values, option) => {
    const organizationId = option.value;
    const organizationName = option.children;
    setSite((prevState) => ({
      ...prevState,
      organizationId,
      organizationName,
    }));
  };

  const onSelectReqApproval = (value) => {
    setIsReqApproval(value.target.checked);
  };
  const onSelectDirectory = (value) => {
    setIsDirectory(value.target.checked);
  };
  const onSelectCommercial = (value) => {
    setIsCommercial(value.target.checked);
  };

  const onSelectIsAdsum = (value) => {
    setSite({ ...site, isAdsum: value.target.checked });
    setIsAdsum(value.target.checked);
  };

  const handleSave = (values) => {
    if (regexInput(values.name) && regexDescription(values.description)) {
      onCreate({
        ...site,
        id: site._id,
        name: values.name,
        organizationId: values.organization,
        organizationName: organizations.find(
          (org) => org._id === values.organization
        )?.name,
        propertiesName: values.propertiesName,
        description: values.description,
        adsumSiteId: values.adsumSiteId,
        adsumUsername: values.adsumUsername,
        adsumKey: values.adsumKey,
        adsumToken: values.adsumToken,
        isReqApproval,
        isCommercial,
        isDirectory,
      });
      form.resetFields();
    } else {
      setErrors({
        ...errors,
        name:
          !regexInput(values.name) &&
          'This field cannot contain any special characters',
        description:
          !regexDescription(values.description) &&
          'This field cannot contain any special characters',
      });
    }
  };

  return (
    <Modal
      className="adsign-modal"
      visible={visible}
      centered
      title={isEditing ? 'Edit Site' : 'Create New Site'}
      okText="Submit"
      confirmLoading={loading}
      cancelButtonProps={{ ghost: true, danger: true }}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      footer={
        <div>
          <button
            className="button-danger-secondary mr-2"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              form
                .validateFields()
                .then((values) => {
                  handleSave(values);
                })
                .catch((info) => {
                  log('Validate Failed:', info);
                });
            }}
          >
            Submit
          </button>
        </div>
      }
    >
      <Form
        className="form"
        layout="vertical"
        form={form}
        onChange={onChangeHandler}
        initialValues={{
          name: site.name,
          organization: site.organizationId,
          propertiesName: site.propertiesName,
          description: site.description,
          isReqApproval: site.isReqApproval,
          isCommercial: site.isCommercial,
          isDirectory: site.isDirectory,
          adsumSiteId: site.adsumSiteId,
          adsumToken: site.adsumToken,
          adsumUsername: site.adsumUsername,
          adsumKey: site.adsumKey,
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          validateStatus={errors.name ? 'error' : 'success'}
          help={errors.name ? errors.name : null}
        >
          <Input placeholder="Enter Site's Name" />
        </Form.Item>
        <Form.Item
          label="Properties Name"
          name="propertiesName"
          validateStatus={errors.propertiesName ? 'error' : 'success'}
          help={errors.propertiesName ? errors.propertiesName : null}
        >
          <Input placeholder="Enter Site's Properties Name in AEM" />
        </Form.Item>
        <Form.Item name="organization" label="Organization">
          <Select
            placeholder="Select Organization"
            onChange={onSelectOrganizationHandler}
            allowClear
          >
            {organizations &&
              organizations.map((organization) => (
                <Option value={organization._id} key={organization.id}>
                  {organization.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="isAdsum" valuePropName="isAdsum" value={site.isAdsum}>
          <Checkbox
            onChange={onSelectIsAdsum}
            checked={isAdsum}
            initialValues={site.isAdsum}
          >
            Site is an adsum site
          </Checkbox>
        </Form.Item>
        {isAdsum && (
          <>
            <Form.Item
              label="Adsum Site Id"
              name="adsumSiteId"
              validateStatus={errors.adsumSiteId ? 'error' : 'success'}
              help={errors.adsumSiteId ? errors.adsumSiteId : null}
            >
              <Input placeholder="Enter Adsum Site Id" />
            </Form.Item>
            <Form.Item
              label="Adsum Username"
              name="adsumUsername"
              validateStatus={errors.adsumUsername ? 'error' : 'success'}
              help={errors.adsumUsername ? errors.adsumUsername : null}
            >
              <Input placeholder="Enter Adsum Username" />
            </Form.Item>
            <Form.Item
              label="Adsum Key"
              name="adsumKey"
              validateStatus={errors.adsumKey ? 'error' : 'success'}
              help={errors.adsumKey ? errors.adsumKey : null}
            >
              <Input placeholder="Enter Adsum Key" />
            </Form.Item>
            <Form.Item
              label="Adsum Token"
              name="adsumToken"
              validateStatus={errors.adsumToken ? 'error' : 'success'}
              help={errors.adsumToken ? errors.adsumToken : null}
            >
              <Input placeholder="Enter Adsum Token" />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="isCommercial"
          valuePropName="isCommercial"
          value={site.isCommercial}
        >
          <Checkbox
            onChange={onSelectCommercial}
            checked={isCommercial}
            initialValues={site.isCommercial}
          >
            Site is a commercial site
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="isReqApproval"
          valuePropName="isReqApproval"
          value={site.isReqApproval}
        >
          <Checkbox
            onChange={onSelectReqApproval}
            checked={isReqApproval}
            initialValues={site.isReqApproval}
          >
            Approval is Required
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="isDirectory"
          valuePropName="isDirectory"
          value={site.isDirectory}
        >
          <Checkbox
            onChange={onSelectDirectory}
            checked={isDirectory}
            initialValues={site.isDirectory}
          >
            Site has directory view
          </Checkbox>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          validateStatus={errors.description ? 'error' : 'success'}
          help={errors.description ? errors.description : null}
        >
          <Input.TextArea rows={4} placeholder="Description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalSiteForm.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
};

export default ModalSiteForm;
