import { createReducer } from 'redux-act';

import {
  OVERVIEW_FETCH_SITES_INIT,
  OVERVIEW_FETCH_SITES_SUCCESS,
  OVERVIEW_FETCH_SITES_FAIL,
  OVERVIEW_FETCH_WEEKLY_INIT,
  OVERVIEW_FETCH_WEEKLY_SUCCESS,
  OVERVIEW_FETCH_WEEKLY_FAIL,
  OVERVIEW_FETCH_HOURLY_INIT,
  OVERVIEW_FETCH_HOURLY_SUCCESS,
  OVERVIEW_FETCH_HOURLY_FAIL,
  OVERVIEW_FETCH_POI_INIT,
  OVERVIEW_FETCH_POI_SUCCESS,
  OVERVIEW_FETCH_POI_FAIL,
  OVERVIEW_FETCH_CATEGORY_INIT,
  OVERVIEW_FETCH_CATEGORY_SUCCESS,
  OVERVIEW_FETCH_CATEGORY_FAIL,
  OVERVIEW_FETCH_KIOSK_INIT,
  OVERVIEW_FETCH_KIOSK_SUCCESS,
  OVERVIEW_FETCH_KIOSK_FAIL,
  OVERVIEW_CHANGE_DATE,
} from 'state/actions/overview';

const initialState = {
  dateRange: { startDate: '', endDate: '' },
};

export const overviewReducer = createReducer(
  {
    [OVERVIEW_FETCH_SITES_INIT]: (state) => ({
      ...state,
      loadingSite: true,
      siteData: [],
    }),
    [OVERVIEW_FETCH_SITES_SUCCESS]: (state, payload) => ({
      ...state,
      siteData: payload.data || [],
      loadingSite: false,
      error: null,
    }),
    [OVERVIEW_FETCH_SITES_FAIL]: (state, payload) => ({
      ...state,
      loadingSite: false,
      error: payload.error,
    }),
    [OVERVIEW_FETCH_WEEKLY_INIT]: (state) => ({
      ...state,
      loadingWeekly: true,
      weeklyData: [],
    }),
    [OVERVIEW_FETCH_WEEKLY_SUCCESS]: (state, payload) => ({
      ...state,
      weeklyData: payload.data || [],
      loadingWeekly: false,
      error: null,
    }),
    [OVERVIEW_FETCH_WEEKLY_FAIL]: (state, payload) => ({
      ...state,
      loadingWeekly: false,
      error: payload.error,
    }),
    [OVERVIEW_FETCH_HOURLY_INIT]: (state) => ({
      ...state,
      loadingHourly: true,
      hourlyData: [],
    }),
    [OVERVIEW_FETCH_HOURLY_SUCCESS]: (state, payload) => ({
      ...state,
      hourlyData: payload.data || [],
      loadingHourly: false,
      error: null,
    }),
    [OVERVIEW_FETCH_HOURLY_FAIL]: (state, payload) => ({
      ...state,
      loadingHourly: false,
      error: payload.error,
    }),
    [OVERVIEW_FETCH_POI_INIT]: (state) => ({
      ...state,
      loadingPoi: true,
      poiData: [],
    }),
    [OVERVIEW_FETCH_POI_SUCCESS]: (state, payload) => ({
      ...state,
      poiData: payload.data || [],
      loadingPoi: false,
      error: null,
    }),
    [OVERVIEW_FETCH_POI_FAIL]: (state, payload) => ({
      ...state,
      loadingPoi: false,
      error: payload.error,
    }),
    [OVERVIEW_FETCH_CATEGORY_INIT]: (state) => ({
      ...state,
      loadingCategory: true,
      categoryData: [],
    }),
    [OVERVIEW_FETCH_CATEGORY_SUCCESS]: (state, payload) => ({
      ...state,
      categoryData: payload.data || [],
      loadingCategory: false,
      error: null,
    }),
    [OVERVIEW_FETCH_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loadingCategory: false,
      error: payload.error,
    }),
    [OVERVIEW_FETCH_KIOSK_INIT]: (state) => ({
      ...state,
      loadingKiosk: true,
      kioskData: [],
    }),
    [OVERVIEW_FETCH_KIOSK_SUCCESS]: (state, payload) => ({
      ...state,
      kioskData: payload.data || [],
      loadingKiosk: false,
      error: null,
    }),
    [OVERVIEW_FETCH_KIOSK_FAIL]: (state, payload) => ({
      ...state,
      loadingKiosk: false,
      error: payload.error,
    }),
    [OVERVIEW_CHANGE_DATE]: (state, payload) => ({
      ...state,
      dateRange: payload.data,
    }),
  },
  initialState
);
