import { Empty } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
// import { ImportIcon } from 'assets/icon/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'underscore';
import { CircularProgress } from '@material-ui/core';
import TenantCards from 'design-components/TenantCards';
import ModalPreview from 'components/ModalPreview';
import { deleteTenant, fetchTenants } from 'state/actions/tenants';
import ModalCodeBlock from 'components/ModalCodeBlock';
import './Pois.css';
import { filterAccess } from 'components/Report/utils';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalDelete from 'components/ModalDelete';
// import { setTabTenant } from 'state/actions/breadcrumb';

// const { Option } = Select;
// const { Panel } = Collapse;

function Tenants({ history }) {
  const [search, setSearch] = useState();

  const [modalPreview, setModalPreview] = useState({ isOpen: false });
  const [modalCode, setModalCode] = useState({ isOpen: false });

  const { siteId, siteName } = useParams();

  const { tenantsList, loading, userData } = useSelector(
    (state) => ({
      tenantsList: state.tenants.tenant,
      unitNumber: state.tenants.unitnumber,
      categoriesList: state.categories.list,
      loading: state.tenants.loading,
      downloading: state.tenants.downloading,
      deleted: state.tenants.deleted,
      floorList: state.floor.list,
      category: state.categories.category,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      categoryLoading: state.categories.loading,
      floor: state.floor.floor,
      floorLoading: state.floor.loading,
      selectedTab: state.breadcrumb.tenant,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTenants({ siteId }));
  }, [siteId]); // eslint-disable-line

  // const handleUpload = () => {
  //   dispatch(syncPois());
  // };

  const handleSearch = (e) => {
    // setSearchError(null);
    // if (!regexInput(e.target.value) && e.target.value) {
    //   setSearchError('Search cannot using special character');
    // }
    setSearch(e.target.value);
  };

  const onDeleteMedia = (mediaId) => {
    setDeleteModal(true);
    setDeletedId(mediaId);
  };

  const filteredData = search
    ? tenantsList.filter((el) => {
        const clonedElem = { ...el };
        const name = clonedElem['jcr:title']
          ?.toLowerCase()
          .includes(search.toLowerCase());
        const location =
          clonedElem.locations?.filter((loc) =>
            loc?.toLowerCase().includes(search.toLowerCase())
          ).length > 0;
        // console.log(location);
        if (name || location) {
          return clonedElem;
        }
        return false;
      })
    : tenantsList;

  return (
    <div className="media-library">
      <ModalCodeBlock
        isVisible={modalCode.isOpen}
        data={modalCode.data}
        title="Tenant Raw Data"
        onCancel={() => {
          setModalCode({ isOpen: false, data: {} });
        }}
      />
      <ModalPreview
        isVisible={modalPreview.isOpen}
        data={modalPreview.data}
        title="Preview Tenant"
        onCancel={() => {
          setModalPreview({ isOpen: false, data: {} });
        }}
      />

      <ModalDelete
        isVisible={deleteModal}
        onOk={() =>
          dispatch(
            deleteTenant({ id: deletedId, siteId }, () => {
              setDeleteModal(false);
              dispatch(fetchTenants({ siteId }));
            })
          )
        }
        onCancel={() => setDeleteModal(false)}
        title="Delete Tenant"
        subtitle=""
        loading={loading}
      />

      <div className="media-header">
        <div className="media-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="actions__wrapper">
          {filterAccess({
            userData,
            siteId,
            param: 'poi',
            action: 'create',
          }) && (
            <Link
              to={`/${siteId}/information/${siteName}/tenant/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-1" />
              Add Tenant
            </Link>
          )}

          {/* <button className="btn-outline-primary" onClick={handleUpload}>
            {downloading ? (
              <CircularProgress color="#64D5D2" size={18} />
            ) : (
              <ImportIcon color="#64D5D2" size={18} />
            )}

            {'Fetch Tenant'}
          </button> */}
        </div>
      </div>

      <div className="media-header">
        <div className="media-header-left" />
      </div>
      <div className="media-content-wrapper pois-content">
        <div className="media-content ">
          <div className="media-list-content ">
            {filteredData.length > 0 && filteredData.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk) => {
                return (
                  <div className="media-list-chunk" key={Math.random()}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="media-list"
                        >
                          <TenantCards
                            data={poi}
                            isShowMedia
                            previewAction={() =>
                              setModalPreview({ isOpen: true, data: poi })
                            }
                            dataAction={() =>
                              setModalCode({ isOpen: true, data: poi })
                            }
                            deleteAction={
                              filterAccess({
                                userData,
                                siteId,
                                param: 'poi',
                                action: 'delete',
                              })
                                ? () => onDeleteMedia(poi._id)
                                : null
                            }
                            editAction={
                              filterAccess({
                                userData,
                                siteId,
                                param: 'poi',
                                action: 'update',
                              })
                                ? (id) =>
                                    history.push(
                                      `/${siteId}/information/${siteName}/tenant/${id}`
                                    )
                                : null
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="media-list-empty" />}
                    {chunk.length < 3 && <div className="media-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="media-empty">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Empty image={EmptyIcon} description="No Tenant" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tenants;
