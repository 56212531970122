import React, { useState, Suspense, useEffect } from 'react';
import CategoryDropdown from 'components/CategoryDropdown';
import { DatePicker } from 'antd';
import { FilterIcon } from 'assets/icon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import PageTitle from 'elements/page-title';
import { changeDate } from 'state/actions/overview';
import './OverviewAnalytics.css';

// Top mall action chart component
const TopMallChart = React.lazy(() => import('./TopSitesMallChart'));
const MemoizedTopMall = React.memo(TopMallChart, (prevProps, nextProps) => {
  // Only re-render if relevant data prop changes
  return prevProps?.data === nextProps?.data;
});
// Weekly action chart component
const WeeklyActionChart = React.lazy(() => import('./WeelyActionChart'));
const MemoizedWeeklyChart = React.memo(
  WeeklyActionChart,
  (prevProps, nextProps) => {
    // Only re-render if relevant data prop changes
    return prevProps?.data === nextProps?.data;
  }
);
// Top 10 poi chart component
const TopPoiChart = React.lazy(() => import('./TopPoiChart'));
const MemoizedTopPoi = React.memo(TopPoiChart, (prevProps, nextProps) => {
  // Only re-render if relevant data prop changes
  return prevProps?.data === nextProps?.data;
});
// Top 10 category chart component
const TopCategoryChart = React.lazy(() => import('./TopCategoryChart'));
const MemoizedTopCategory = React.memo(
  TopCategoryChart,
  (prevProps, nextProps) => {
    // Only re-render if relevant data prop changes
    return prevProps?.data === nextProps?.data;
  }
);

// Top 10 category chart component
const TopKioskChart = React.lazy(() => import('./TopKioskChart'));
const MemoizedTopKiosk = React.memo(TopKioskChart, (prevProps, nextProps) => {
  // Only re-render if relevant data prop changes
  return prevProps?.data === nextProps?.data;
});

// Hourly Chart component
const HourlyActionChart = React.lazy(() => import('./HourlyActionChart'));
const MemoizedHourlyChart = React.memo(
  HourlyActionChart,
  (prevProps, nextProps) => {
    // Only re-render if relevant data prop changes
    return prevProps?.data === nextProps?.data;
  }
);

const OverviewAnalytics = () => {
  // const { siteId } = useParams();

  const { loading } = useSelector(
    (state) => ({
      analytics: state.analytic.data,
      loading: state.analytic.loading,
      locations: state.analytic.locations,
      screens: state.screens.list,
      loadingScreen: state.screens.loading,
      deleted: state.screens.deleted,
      updated: state.screens.updated,
    }),
    shallowEqual
  );

  // select report type 'poi' or 'place'
  const [typeOpen, setTypeOpen] = useState(false);
  const [selectedType, setSelectedType] = useState();
  const [tempSelectedType, setTempSelectedType] = useState(selectedType);
  // select report type 'poi' or 'place'
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const chartType = [
    { _id: 'place', name: 'Unit Number' },
    { _id: 'poi', name: 'POI' },
    { _id: 'category', name: 'Category' },
    { _id: 'event', name: 'Events' },
    { _id: 'promotion', name: 'Deals' },
    { _id: 'qrcode', name: 'QR Code' },
    { _id: 'leaderboard', name: 'Kiosk Performance' },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeDate({ startDate: '', endDate: '' }));
  }, []); // eslint-disable-line

  const handleSetType = () => {
    setSelectedType(tempSelectedType);
    setTypeOpen(false);
  };

  const getType = (type) => {
    const findCategory = chartType.find((cat) => cat._id === type);
    return findCategory?.name;
  };

  const handleDateChange = (range) => {
    const startDate = range && range[0].valueOf();
    const endDate = range && range[1].valueOf();

    const timeleft = endDate - startDate;
    const days = Math.ceil(timeleft / 1000 / 60 / 60 / 24);
    console.log(startDate, endDate, days, 'range date picker');

    setDateRange((prevState) => ({
      ...prevState,
      startDate,
      endDate,
    }));
    dispatch(changeDate({ startDate, endDate }));
  };

  // const onDeleteScreenHandler = () => {
  //   dispatch(deleteScreen(deleteModal.screenId, siteId));
  // };

  return (
    <section className="overviewanalytic">
      {/* <ModalScreenForm
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onCreate={onCreate}
        screen={screen}
        setScreen={setScreen}
        isEditing={isEditing}
        handleDeleteCategory={(cat) => onRemoveButtonClickHandler(cat._id)}
      /> */}
      {/* {deleteModal.isOpen && (
          <Modal
            title="
          Are you sure want to delete these data?
          "
            visible={deleteModal.isOpen}
            onOk={onDeleteScreenHandler}
            onCancel={onCloseModalHandler}
          >
            Data cannot be restored after delete
          </Modal>
        )} */}
      <PageTitle>Analytics</PageTitle>
      <div className="overviewanalytic-header">
        <div className="overviewanalytic-header-left">
          <div
            className="playlist-wrapper"
            onClick={() => !loading && setTypeOpen(!typeOpen)}
          >
            <div className="playlist-selection">
              <FilterIcon />
              <span>{!selectedType ? 'Type' : getType(selectedType)}</span>
            </div>
            <i
              className={`feather-chevron-down ${
                typeOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={chartType}
              modalOpen={typeOpen}
              handleSetPlaylist={handleSetType}
              setTempSelectedCategory={(id) => setTempSelectedType(id)}
              tempSelectedCategory={tempSelectedType}
            />
          </div>

          <div>
            <DatePicker.RangePicker
              className="overviewanalytic-date-picker"
              onChange={handleDateChange}
            />
          </div>
        </div>

        {/* {selectedType && selectedType !== 'qrcode' && (
            <div className="overviewanalytic-header-right">
              <button
                className="btn-outline-primary"
                onClick={() =>
                  dispatch(
                    downloadAnalytics({
                      start: dateRange.startDate,
                      end: dateRange.endDate,
                      adsumScreenIds: selectedDevice,
                      type: selectedType,
                      siteId,
                    })
                  )
                }
              >
                <ExportIcon color="#64D5D2" size={18} />
                {selectedType === 'leaderboard'
                  ? 'Export Summary'
                  : 'Export Raw Data'}
              </button>
            </div>
          )} */}
      </div>
      <div className="overviewanalytic-content-wrapper">
        <Suspense fallback={<CircularProgress />}>
          <MemoizedTopMall data={dateRange} />
        </Suspense>
        <Suspense fallback={<CircularProgress />}>
          <MemoizedWeeklyChart data={dateRange} />
        </Suspense>
        <div className="row">
          <Suspense fallback={<CircularProgress />}>
            <MemoizedTopPoi data={dateRange} />
          </Suspense>
          <Suspense fallback={<CircularProgress />}>
            <MemoizedTopCategory data={dateRange} />
          </Suspense>
          <Suspense fallback={<CircularProgress />}>
            <MemoizedTopKiosk data={dateRange} />
          </Suspense>
        </div>
        <Suspense fallback={<CircularProgress />}>
          <MemoizedHourlyChart data={dateRange} />
        </Suspense>
        {/* <div className="overviewanalytic-content">
            {selectedType ? (
              selectedType === 'leaderboard' ? (
                <LeaderboardReport siteId={siteId} loading={loading} />
              ) : (
                <ProfileAnalyzerReport
                  siteId={siteId}
                  loading={loading}
                  analytics={analytics}
                  longDateRange={longDateRange}
                  selectedType={selectedType}
                  onExport={() =>
                    dispatch(
                      downloadAnalyticsLeaderboard({
                        start: dateRange.startDate,
                        end: dateRange.endDate,
                        adsumScreenIds: selectedDevice,
                        type: selectedType,
                        siteId,
                      })
                    )
                  }
                />
              )
            ) : (
              <div className="empty-content">
                <EmptyAnalyticIcon />
                <h1 className="empty-title">
                  Select the type of data analytics
                </h1>
                <p>
                  Please select the data type first to display the analytics
                  data to be shown
                </p>
              </div>
            )}
          </div> */}
      </div>
    </section>
  );
};

export default OverviewAnalytics;
