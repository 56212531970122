import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg } from 'utils';
import { url } from 'utils/url';

export const NOTIFICATIONS_FETCH_DATA_INIT = createAction(
  'NOTIFICATIONS_FETCH_DATA_INIT'
);
export const NOTIFICATIONS_FETCH_DATA_SUCCESS = createAction(
  'NOTIFICATIONS_FETCH_DATA_SUCCESS'
);
export const NOTIFICATIONS_FETCH_DATA_FAIL = createAction(
  'NOTIFICATIONS_FETCH_DATA_FAIL'
);
export const NOTIFICATIONS_UPDATE_DATA_INIT = createAction(
  'NOTIFICATIONS_UPDATE_DATA_INIT'
);
export const NOTIFICATIONS_UPDATE_DATA_SUCCESS = createAction(
  'NOTIFICATIONS_UPDATE_DATA_SUCCESS'
);
export const NOTIFICATIONS_UPDATE_DATA_FAIL = createAction(
  'NOTIFICATIONS_UPDATE_DATA_FAIL'
);

export const NOTIFICATIONS_CLEAN_UP = createAction('NOTIFICATIONS_CLEAN_UP');

export const fetchNotifications = () => {
  return async (dispatch) => {
    dispatch(NOTIFICATIONS_FETCH_DATA_INIT());
    let notifications;
    try {
      notifications = await axios.get(`${url}/notification/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(NOTIFICATIONS_FETCH_DATA_FAIL({ error }));
    }

    return dispatch(
      NOTIFICATIONS_FETCH_DATA_SUCCESS({
        list: notifications.data.data,
      })
    );
  };
};
export const updateNotification = ({ id, status }) => {
  return async (dispatch) => {
    dispatch(NOTIFICATIONS_UPDATE_DATA_INIT());
    let notifications;
    try {
      notifications = await axios.post(
        `${url}/notification/edit`,
        {
          id,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(NOTIFICATIONS_UPDATE_DATA_FAIL({ error }));
    }
    // console.log(notifications.data);

    dispatch(
      NOTIFICATIONS_UPDATE_DATA_SUCCESS({
        list: notifications.data.data,
      })
    );

    return dispatch(fetchNotifications());
  };
};

export const notificationsCleanUp = () => (dispatch) =>
  dispatch(NOTIFICATIONS_CLEAN_UP());
