/* eslint-disable camelcase */
import { Checkbox, Dropdown, Input, Menu, Modal, Select } from 'antd';
import PageHeader from 'design-components/PageHeader';
import paths from 'pages/Router/paths';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setBreadcrumb } from 'state/actions/breadcrumb';
import {
  createRole,
  deleteRole,
  fetchRoles,
  modifyRole,
} from 'state/actions/roles';
import { fetchUsers } from 'state/actions/users';
import _ from 'underscore';
import RolesTable from './RolesTable';
import './Users.scss';

function Role() {
  const [search, setSearch] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState('All');
  const [data, setData] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [form, setForm] = useState({
    id: '',
    name: '',
    access: [],
  });

  const { roleList, loading, role, sites, organizations, success, created } =
    useSelector(
      (state) => ({
        roleList: state.roles.list,
        userList: state.users.list,
        // isAdmin: state.auth.userData.isAdmin,
        error: state.roles.error,
        loading: state.roles.loading,
        deleted: state.roles.deleted,
        userData: state.auth.userData,
        role: state.auth.userData.role,
        sites: state.sites.list,
        organizations: state.organizations.list,
        success: state.roles.success,
        created: state.roles.created,
      }),
      shallowEqual
    );

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 'admin' || role === 'superadmin') {
      dispatch(fetchUsers());
    }
  }, [dispatch, role]);

  useEffect(() => {
    if (success && !loading) {
      setIsModalShow(false);
      setForm({
        id: '',
        name: '',
        access: [],
      });
    }
  }, [success, loading]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(
      setBreadcrumb([
        { menu: 'Access Management', link: `/roles` },
        { menu: 'Roles', link: `/roles` },
      ])
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (created) {
      dispatch(fetchRoles());
    }
  }, [created]); // eslint-disable-line

  useEffect(() => {
    if (sites) {
      setData([
        ...data,
        ..._.sortBy(sites, 'name').map((site) => {
          return {
            id: site._id,
            title:
              site.name.length > 20
                ? `${site.name.substr(0, 20)}...`
                : site.name,
            key: site._id,
            site_id: site._id,
            organization_id: site.organizationId,
            children: [
              {
                key: `media-${site._id}`,
                organization_id: site.organizationId,
                id: `media-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Media',
                module: 'media',
                children: [
                  {
                    key: `read-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Read Media',
                    type: 'read',
                    module: 'media',
                  },
                  {
                    key: `create-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Create Media',
                    type: 'create',
                    module: 'media',
                  },
                  {
                    key: `update-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Update Media',
                    type: 'update',
                    module: 'media',
                  },
                  {
                    key: `delete-media-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-media-${site._id}`,
                    parent_id: `media-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Media',
                    type: 'delete',
                    module: 'media',
                  },
                ],
              },
              {
                key: `poi-${site._id}`,
                organization_id: site.organizationId,
                id: `poi-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Tenant',
                module: 'poi',
                children: [
                  {
                    key: `read-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Read Tenant',
                    type: 'read',
                    module: 'poi',
                  },
                  {
                    key: `create-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Create Tenant',
                    type: 'create',
                    module: 'poi',
                  },
                  {
                    key: `update-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Update Tenant',
                    type: 'update',
                    module: 'poi',
                  },
                  {
                    key: `delete-poi-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-poi-${site._id}`,
                    parent_id: `poi-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Tenant',
                    type: 'delete',
                    module: 'poi',
                  },
                ],
              },
              {
                key: `information-${site._id}`,
                organization_id: site.organizationId,
                id: `information-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Information',
                module: 'information',
                children: [
                  {
                    key: `read-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Read Information',
                    type: 'read',
                    module: 'information',
                  },
                  {
                    key: `create-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Create Information',
                    type: 'create',
                    module: 'information',
                  },
                  {
                    key: `update-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Update Information',
                    type: 'update',
                    module: 'information',
                  },
                  {
                    key: `delete-information-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-information-${site._id}`,
                    parent_id: `information-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Information',
                    type: 'delete',
                    module: 'information',
                  },
                ],
              },
              {
                key: `event-${site._id}`,
                organization_id: site.organizationId,
                id: `event-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Event',
                module: 'event',
                children: [
                  {
                    key: `read-event-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-event-${site._id}`,
                    parent_id: `event-${site._id}`,
                    site_id: site._id,
                    title: 'Read Event',
                    type: 'read',
                    module: 'event',
                  },
                  {
                    key: `create-event-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-event-${site._id}`,
                    parent_id: `event-${site._id}`,
                    site_id: site._id,
                    title: 'Create Event',
                    type: 'create',
                    module: 'event',
                  },
                  {
                    key: `update-event-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-event-${site._id}`,
                    parent_id: `event-${site._id}`,
                    site_id: site._id,
                    title: 'Update Event',
                    type: 'update',
                    module: 'event',
                  },
                  {
                    key: `delete-event-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-event-${site._id}`,
                    parent_id: `event-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Event',
                    type: 'delete',
                    module: 'event',
                  },
                ],
              },
              {
                key: `deal-${site._id}`,
                organization_id: site.organizationId,
                id: `deal-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Deal',
                module: 'deal',
                children: [
                  {
                    key: `read-deal-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-deal-${site._id}`,
                    parent_id: `deal-${site._id}`,
                    site_id: site._id,
                    title: 'Read Deal',
                    type: 'read',
                    module: 'deal',
                  },
                  {
                    key: `create-deal-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-deal-${site._id}`,
                    parent_id: `deal-${site._id}`,
                    site_id: site._id,
                    title: 'Create Deal',
                    type: 'create',
                    module: 'deal',
                  },
                  {
                    key: `update-deal-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-deal-${site._id}`,
                    parent_id: `deal-${site._id}`,
                    site_id: site._id,
                    title: 'Update Deal',
                    type: 'update',
                    module: 'deal',
                  },
                  {
                    key: `delete-deal-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-deal-${site._id}`,
                    parent_id: `deal-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Deal',
                    type: 'delete',
                    module: 'deal',
                  },
                ],
              },
              {
                key: `analytic-${site._id}`,
                organization_id: site.organizationId,
                id: `analytic-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Analytic',
                module: 'analytic',
                children: [
                  {
                    key: `read-analytic-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-analytic-${site._id}`,
                    parent_id: `analytic-${site._id}`,
                    site_id: site._id,
                    title: 'Read Analytic',
                    type: 'read',
                    module: 'analytic',
                  },
                ],
              },
              {
                key: `config-${site._id}`,
                organization_id: site.organizationId,
                id: `config-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Config',
                module: 'config',
                children: [
                  {
                    key: `read-config-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-config-${site._id}`,
                    parent_id: `config-${site._id}`,
                    site_id: site._id,
                    title: 'Read Config',
                    type: 'read',
                    module: 'config',
                  },
                  {
                    key: `create-config-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-config-${site._id}`,
                    parent_id: `config-${site._id}`,
                    site_id: site._id,
                    title: 'Create Config',
                    type: 'create',
                    module: 'config',
                  },
                  {
                    key: `update-config-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-config-${site._id}`,
                    parent_id: `config-${site._id}`,
                    site_id: site._id,
                    title: 'Update Config',
                    type: 'update',
                    module: 'config',
                  },
                  {
                    key: `delete-config-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-config-${site._id}`,
                    parent_id: `config-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Config',
                    type: 'delete',
                    module: 'config',
                  },
                ],
              },

              {
                key: `screen-${site._id}`,
                organization_id: site.organizationId,
                id: `screen-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Screen',
                module: 'screen',
                children: [
                  {
                    key: `read-screen-${site._id}`,
                    organization_id: site.organizationId,
                    id: `read-screen-${site._id}`,
                    parent_id: `screen-${site._id}`,
                    site_id: site._id,
                    title: 'Read Screen',
                    type: 'read',
                    module: 'screen',
                  },
                  {
                    key: `create-screen-${site._id}`,
                    organization_id: site.organizationId,
                    id: `create-screen-${site._id}`,
                    parent_id: `screen-${site._id}`,
                    site_id: site._id,
                    title: 'Create Screen',
                    type: 'create',
                    module: 'screen',
                  },
                  {
                    key: `update-screen-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-screen-${site._id}`,
                    parent_id: `screen-${site._id}`,
                    site_id: site._id,
                    title: 'Update Screen',
                    type: 'update',
                    module: 'screen',
                  },
                  {
                    key: `delete-screen-${site._id}`,
                    organization_id: site.organizationId,
                    id: `delete-screen-${site._id}`,
                    parent_id: `screen-${site._id}`,
                    site_id: site._id,
                    title: 'Delete Screen',
                    type: 'delete',
                    module: 'screen',
                  },
                ],
              },
              {
                key: `approval-${site._id}`,
                organization_id: site.organizationId,
                id: `approval-${site._id}`,
                parent_id: site._id,
                site_id: site._id,
                title: 'Approval',
                module: 'approval',
                children: [
                  {
                    key: `update-approval-${site._id}`,
                    organization_id: site.organizationId,
                    id: `update-approval-${site._id}`,
                    parent_id: `approval-${site._id}`,
                    site_id: site._id,
                    title: 'Approval Update',
                    type: 'update',
                    module: 'approval',
                  },
                ],
              },
            ],
          };
        }),
      ]);
    }
  }, [sites]); // eslint-disable-line

  const handleChangeInput = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (form.id) {
      dispatch(modifyRole({ ...form, id: form._id }));
    } else {
      dispatch(createRole(form));
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleChangeOrg = (value) => {
    setSelectedOrganization(value);
  };

  const handleAllow = (id, siteId, module, type, checked) => {
    const findRoleList = [...roleList].find((x) => x._id === id);
    const findAccess = [...findRoleList.access].find(
      (x) => x.siteId === siteId
    );
    if (findAccess) {
      if (findAccess[module]) {
        findAccess[module][type] = checked;
        dispatch(modifyRole({ ...findRoleList, id }));
      } else {
        findAccess[module] = {
          create: false,
          read: false,
          update: false,
          delete: false,
        };
        findAccess[module][type] = checked;
        dispatch(modifyRole({ ...findRoleList, id }));
      }
    } else {
      const allows = {
        media: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        poi: {
          read: false,
        },
        analytic: {
          read: false,
        },
        information: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        event: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        deal: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        config: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        screen: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        approval: {
          update: false,
        },
      };

      allows[module][type] = checked;
      allows.siteId = siteId;

      const newAccess = {
        ...findRoleList,
        access: findRoleList.access
          ? [...findRoleList.access, allows]
          : [allows],
      };
      dispatch(modifyRole({ ...newAccess, id: newAccess._id }));
    }
  };

  const handleAllowSome = (id, siteId, module, checked) => {
    const findRoleList = [...roleList].find((x) => x._id === id);
    const findAccess = [...findRoleList.access].find(
      (x) => x.siteId === siteId
    );

    if (findAccess) {
      if (findAccess[module]) {
        if (findAccess[module].create !== undefined) {
          findAccess[module].create = checked;
        }
        if (findAccess[module].delete !== undefined) {
          findAccess[module].delete = checked;
        }
        if (findAccess[module].read !== undefined) {
          findAccess[module].read = checked;
        }
        if (findAccess[module].update !== undefined) {
          findAccess[module].update = checked;
        }
      } else {
        findAccess[module] = {
          create: true,
          delete: true,
          read: true,
          update: true,
        };
      }
      dispatch(modifyRole({ ...findRoleList, id }));
    } else {
      const allows = {
        media: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        poi: {
          read: false,
        },
        analytic: {
          read: false,
        },
        information: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        event: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        deal: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        config: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },

        screen: {
          create: false,
          read: false,
          update: false,
          delete: false,
        },
        approval: {
          update: false,
        },
        // feedback: {
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
        // map: {
        //   create: false,
        //   read: false,
        //   update: false,
        //   delete: false,
        // },
      };

      allows[module].create = checked;
      allows[module].delete = checked;
      allows[module].read = checked;
      allows[module].update = checked;
      allows.siteId = siteId;

      const newAccess = {
        ...findRoleList,
        access: findRoleList.access
          ? [...findRoleList.access, allows]
          : [allows],
      };
      console.log(newAccess, 'new access some');
      dispatch(modifyRole({ ...newAccess, id: newAccess._id }));
    }
  };

  const handleAllowAll = (id, siteId, checked) => {
    const findRoleList = [...roleList].find((x) => x._id === id);
    const findAccess = [...findRoleList.access].find(
      (x) => x.siteId === siteId
    );

    if (findAccess) {
      const accessMap = Object.keys(findAccess);
      if (!accessMap.find((acc) => acc === 'approval')) {
        findAccess.approval = { update: checked };
      }
      accessMap.map((x) => {
        if (x !== 'siteId') {
          findAccess[x].create = checked;
          findAccess[x].delete = checked;
          findAccess[x].read = checked;
          findAccess[x].update = checked;
        }
        return null;
      });

      dispatch(modifyRole({ ...findRoleList, id: findRoleList._id }));
    } else {
      const allows = {
        // api: {
        //   create: checked,
        //   read: checked,
        //   update: checked,
        //   delete: checked,
        // },

        media: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        poi: {
          read: checked,
        },
        analytic: {
          read: checked,
        },
        information: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        event: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        deal: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        config: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },

        screen: {
          create: checked,
          read: checked,
          update: checked,
          delete: checked,
        },
        approval: {
          update: checked,
        },
        siteId,
      };

      const newAccess = {
        ...findRoleList,
        access: findRoleList.access
          ? [...findRoleList.access, allows]
          : [allows],
      };
      console.log(newAccess, 'new access');
      dispatch(modifyRole({ ...newAccess, id: newAccess._id }));
    }
  };

  const handleEdit = (id) => {
    const findRole = roleList.find((x) => x._id === id);
    setForm({
      ...form,
      ...findRole,
      id,
    });
    setIsModalShow(true);
  };

  const handleCloseModal = () => {
    setForm({
      id: '',
      name: '',
      access: [],
    });
    setIsModalShow(false);
  };

  const onRemoveButtonClickHandler = (roleId) => {
    Modal.confirm({
      title: 'Are you sure want to delete this role?',
      content: 'Data cannot be restored after delete',
      onOk: () => dispatch(deleteRole(roleId)),
      okButtonProps: {
        loading,
      },
    });
  };

  const redirect =
    role === 'admin' || role === 'superadmin' ? (
      false
    ) : (
      <Redirect to={paths.ROOT} />
    );

  const columns = [
    {
      title: () => (
        <div className="search-permission">
          <input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Start typing site name"
            className="search-permission-input"
          />
          <i className="feather-search icon-search-permission" />
        </div>
      ),
      dataIndex: 'title',
      key: 'title',
      width: 300,
      fixed: 'left',
    },
  ];

  const columnsPermission = roleList
    ? [
        ...columns,
        ...roleList
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            return 1;
          })
          .map((role) => {
            return {
              title: () => (
                <div className="permission-title">
                  <div className="permission-title-content">
                    <p className="permission-title-text">Name</p>
                    <p className="permission-subtitle-text">{role.name}</p>
                  </div>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="0" onClick={() => handleEdit(role._id)}>
                          <i className="feather-edit mr-1/5" />
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          key="1"
                          onClick={() => onRemoveButtonClickHandler(role._id)}
                        >
                          <i className="feather-delete mr-1/5" />
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <button className="permission-menu">
                      <i className="feather-more-vertical" />
                    </button>
                  </Dropdown>
                </div>
              ),
              dataIndex: 'site_id',
              key: 'site_id',
              align: 'center',
              render: (site_id, record) => {
                const findAccess =
                  role.access &&
                  role.access.find((access) => access.siteId === site_id);

                if (record.module) {
                  if (record.type) {
                    const allow =
                      findAccess &&
                      findAccess[record.module] &&
                      findAccess[record.module][record.type]
                        ? findAccess[record.module][record.type]
                        : false;
                    return (
                      <Checkbox
                        onChange={(e) =>
                          handleAllow(
                            role._id,
                            site_id,
                            record.module,
                            record.type,
                            e.target.checked
                          )
                        }
                        checked={allow}
                      />
                    );
                  }
                  const countChildren = record.children;
                  const totalChildren = findAccess
                    ? _.flatten(
                        countChildren.map(
                          (x) =>
                            findAccess[x.module] && findAccess[x.module][x.type]
                        )
                      )
                    : [];

                  const howManyTrueChildren = totalChildren.filter((x) => x);

                  return (
                    <Checkbox
                      onChange={(e) =>
                        handleAllowSome(
                          role._id,
                          site_id,
                          record.module,
                          e.target.checked
                        )
                      }
                      indeterminate={
                        howManyTrueChildren.length !== totalChildren.length &&
                        howManyTrueChildren.length > 0
                      }
                      checked={
                        howManyTrueChildren.length === totalChildren.length &&
                        findAccess
                      }
                    />
                  );
                }
                const countChildren = record.children;
                const totalChildren = findAccess
                  ? _.flatten(
                      countChildren.map((x) =>
                        x.children.map(
                          (x) =>
                            findAccess[x.module] && findAccess[x.module][x.type]
                        )
                      )
                    )
                  : [];

                const howManyTrueChildren = totalChildren.filter((x) => x);

                return (
                  <Checkbox
                    onChange={(e) =>
                      handleAllowAll(role._id, site_id, e.target.checked)
                    }
                    indeterminate={
                      howManyTrueChildren.length !== totalChildren.length &&
                      howManyTrueChildren.length > 0
                    }
                    checked={
                      howManyTrueChildren.length === totalChildren.length &&
                      findAccess
                    }
                  />
                );
              },
            };
          }),
      ]
    : columns;

  return (
    <section className="roles">
      {redirect}
      <Modal
        title={form.id ? 'Edit Role' : 'Add new Role'}
        visible={isModalShow}
        onCancel={handleCloseModal}
        okButtonProps={{
          loading,
        }}
        okText={form.id ? 'Save Changes' : 'Save'}
        onOk={handleSubmit}
      >
        <label style={{ marginBottom: '0.5em' }}>
          Role Name <span className="is-required">*</span>
        </label>
        <Input
          onChange={(e) => handleChangeInput('name', e.target.value)}
          required
          placeholder="eg: Administrator"
          value={form.name}
        />
      </Modal>
      <PageHeader
        title="Roles"
        isGrid={false}
        isSearch={false}
        isAdd={false}
        addLabel=""
      />
      <div className="roles__table">
        <div className="header-content">
          <div className="left-content">
            <label>Select Organization</label>
            <Select defaultValue="All" onChange={handleChangeOrg}>
              <Select.Option value="All">All Organization</Select.Option>
              {organizations &&
                organizations.map((organization) => {
                  return (
                    <Select.Option
                      value={organization._id}
                      key={organization._id}
                    >
                      {organization.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
          <button
            className="button-primary"
            onClick={() => setIsModalShow(true)}
          >
            <i className="feather-plus" />
            Add Roles
          </button>
        </div>
        <div className="table">
          <RolesTable
            data={data}
            columns={columnsPermission}
            search={search}
            selectedOrganization={selectedOrganization}
            loading={loading}
          />
        </div>
      </div>
    </section>
  );
}

export default Role;
