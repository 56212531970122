import { useFormatMessage } from 'hooks';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import TenantForm from 'components/TenantForm';
// import { createPoi, fetchPois, modifyPoi } from 'state/actions/pois';
import {
  createTenant,
  fetchTenants,
  modifyTenant,
} from 'state/actions/tenants';

const Tenant = ({ history }) => {
  const { id, siteId, siteName } = useParams();

  const { success, tenantsList, tenantData, sitesList, error } = useSelector(
    (state) => ({
      success: state.tenants.success,
      tenantsList: state.tenants.tenant,
      sitesList: state.sites.list,
      error: state.tenants.error,
    }),
    shallowEqual
  );

  const [tenant, setTenant] = useState({});

  const dispatch = useDispatch();

  console.log(tenant, 'tenant data');

  useEffect(() => {
    if (id) {
      const tenantFetched = tenantsList.find(
        (fetchedTenant) => fetchedTenant._id === id
      );
      if (tenantFetched) {
        let data = {};
        if (tenantFetched.openinghours) {
          data = {
            ...tenantFetched,
            starttime: tenantFetched.openinghours.starttime,
            endtime: tenantFetched.openinghours.endtime,
          };
        } else {
          data = tenantFetched;
        }
        setTenant(data);
      } else if (tenantData._id === id) {
        setTenant(tenantData);
      } else {
        dispatch(fetchTenants(id));
      }
    }
  }, [id, tenantData]); // eslint-disable-line

  const isEditing = !!id;

  const tenantForm =
    !tenant && id ? (
      <ClipLoader />
    ) : (
      <TenantForm
        isEditing={isEditing}
        tenant={tenant}
        sites={sitesList}
        setTenant={setTenant}
        action={isEditing ? modifyTenant : createTenant}
        success={success}
        id={id}
        history={history}
        error={error}
      />
    );

  // const redirect = (error || success) && <Redirect to={paths.USERS} />;

  const editTenantMessage = useFormatMessage('Poi.editPoi');

  const newTenantMessage = useFormatMessage('Poi.newPoi');

  return (
    <div className="media">
      <div className="media-header">
        <h1>{isEditing ? editTenantMessage : newTenantMessage}</h1>
        <Link
          to={`/${siteId}/information/${siteName}`}
          className="button-secondary"
        >
          <i className="feather-arrow-left" />
          Back
        </Link>
      </div>
      <div className="media-content">{tenantForm}</div>
    </div>
  );
};

export default Tenant;
