import { Empty, message, Modal, Progress, Upload } from 'antd';
import { EmptyIcon } from 'assets/icon/bigicon';
import { ForwardIcon, LayerIcon, SortIcon } from 'assets/icon';
import { ExportIcon, ImportIcon } from 'assets/icon/dynamic';
import axios from 'axios';
import ModalCategoryForm from 'components/ModalCategoryForm';
import ModalDelete from 'components/ModalDelete';
import ModalFloorForm from 'components/ModalFloorForm';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  createCategory,
  deleteCategory,
  fetchCategories,
  modifyCategory,
  setCategory,
} from 'state/actions/categories';
import {
  createFloor,
  deleteFloor,
  fetchFloor,
  modifyFloor,
  setFloor,
} from 'state/actions/floor';
// import { fetchMaps } from 'state/actions/maps';
import {
  deletePoi,
  downloadPois,
  fetchPois,
  removePoi,
} from 'state/actions/pois';
import _ from 'underscore';
import { getAccess, log } from 'utils';
// import { regexInput } from 'utils/regex';
import { url } from 'utils/url';
import Cards from 'design-components/Cards';
import CategoryDropdown from 'components/CategoryDropdown';
import { setTabTenant } from 'state/actions/breadcrumb';
import {
  createTag,
  deleteTag,
  fetchTags,
  modifyTag,
  setTag,
} from 'state/actions/tag';
import ModalTagForm from 'components/ModalTagForm';
import './Pois.css';
import {
  createSubCategory,
  deleteSubCategory,
  fetchSubCategories,
  modifySubCategory,
  setSubCategory,
} from 'state/actions/subCategories';
import ModalSubCategoryForm from 'components/ModalSubCategoryForm';

// const { Option } = Select;
// const { Panel } = Collapse;

function Pois({ history }) {
  const [search, setSearch] = useState();
  // const [floor, setFloor] = useState();
  const [modalUpload, setModalUpload] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [searchError, setSearchError] = useState(null);
  // const [minValue, setMinValue] = useState(0);
  // const [maxValue, setMaxValue] = useState(16);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [tempSelectedCategory, setTempSelectedCategory] =
    useState(selectedCategory);
  const [playlistOpen, setPlaylistOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  // const [selectedTab, setSelectedTab] = useState('published');

  const [selectedSubCategory, setSelectedSubCategory] = useState('all');
  const [tempSelectedSubCategory, setTempSelectedSubCategory] =
    useState(selectedSubCategory);
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);

  const [selectedFloor, setSelectedFloor] = useState('all');
  const [tempSelectedFloor, setTempSelectedFloor] = useState(selectedFloor);
  const [floorOpen, setFloorOpen] = useState(false);

  const [selectedTag, setSelectedTag] = useState('all');
  const [tempSelectedTag, setTempSelectedTag] = useState(selectedTag);
  const [tagOpen, setTagOpen] = useState(false);

  const [sortBy, setSortBy] = useState({
    sort: 'name',
    direction: 'asc',
  });
  const [sortByTemp, setSortByTemp] = useState(sortBy);
  const [modalCategory, setModalCategory] = useState(false);
  // const [isEditing, setIsEditing] = useState(false);
  const [modalDeleteCategory, setModalDeleteCategory] = useState({
    show: false,
    category: null,
  });
  const [modalDeleteSubCategory, setModalDeleteSubCategory] = useState({
    show: false,
    subCategory: null,
  });

  // const [isEditingFloor, setIsEditingFloor] = useState(false);
  const [modalSubCategory, setModalSubCategory] = useState(false);
  const [modalFloor, setModalFloor] = useState(false);
  const [modalTag, setModalTag] = useState(false);
  const [access, setAccess] = useState(null);
  const [modalDeleteFloor, setModalDeleteFloor] = useState({
    show: false,
    floor: null,
  });
  const [modalDeleteTag, setModalDeleteTag] = useState({
    show: false,
    tag: null,
  });

  const { siteId, siteName } = useParams();

  const {
    poisList,
    loading,
    categoriesList,
    // downloading,
    floorList,
    category,
    categoryLoading,
    subCategoryList,
    subCategory,
    subCategoryLoading,
    userData,
    role,
    floor,
    tag,
    tagList,
    floorLoading,
    tagLoading,
    selectedTab,
  } = useSelector(
    (state) => ({
      poisList: state.pois.list,
      loading: state.pois.loading,
      // downloading: state.pois.downloading,
      deleted: state.pois.deleted,
      categoriesList: state.categories.list,
      floorList: state.floor.list,
      tagList: state.tags.list,
      category: state.categories.category,
      categoryLoading: state.categories.loading,
      subCategoryList: state.subcategories.list,
      subCategory: state.subcategories.subCategory,
      subCategoryLoading: state.subcategories.loading,
      userData: state.auth.userData,
      role: state.auth.userData.role,
      floor: state.floor.floor,
      tag: state.tags.tag,
      floorLoading: state.floor.loading,
      tagLoading: state.tags.loading,
      selectedTab: state.breadcrumb.tenant,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    poisListSorted: null,
    isOpen: false,
  });

  const dispatch = useDispatch();
  const uid = userData.id;

  useEffect(() => {
    dispatch(fetchCategories({ siteId }));
    dispatch(fetchSubCategories({ siteId }));
    dispatch(fetchFloor({ siteId }));
    dispatch(fetchTags({ siteId }));
    setSelectedCategory('all');
    setSelectedFloor('all');
    // dispatch(fetchMaps({ siteId: siteId }));
  }, [siteId]); // eslint-disable-line

  useEffect(() => {
    if (userData && userData.roleAccess) {
      setAccess(getAccess(userData, 'tenant', siteId));
    }
  }, [userData, siteId]);

  useEffect(() => {
    dispatch(
      fetchPois({ siteId, categoryId: selectedCategory, type: selectedTab })
    );
  }, [siteId, selectedCategory, selectedTab]); // eslint-disable-line

  if (poisList) {
    log(poisList, '<<<< pois list');
  }
  const handleDownload = () => {
    dispatch(downloadPois({ siteId, siteName }));
  };

  const onRemoveButtonClickHandler = (poi) => {
    setDeleteModal((prevState) => ({
      poi,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ poi: null, isOpen: false });
  };

  const onDeletePoiHandler = () => {
    log('delete poi clicked', deleteModal);
    if (selectedTab === 'archived') {
      dispatch(
        deletePoi({ id: deleteModal.poi, siteId }, () => {
          onCloseModalHandler();
        })
      );
    } else {
      dispatch(
        removePoi({ id: deleteModal.poi, siteId }, () => {
          onCloseModalHandler();
        })
      );
    }
  };

  const handleUpload = () => {
    setModalUpload(true);
  };

  const onFileChangedHandler = (file) => {
    setIsDragged(false);
    if (file.type !== 'text/csv') {
      message.error('File format must be csv!');
    } else {
      log(file);
      setIsUploaded(true);

      const config = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      };
      const data = new FormData();
      data.append('file', file);
      data.set('siteId', siteId);

      axios
        .post(`${url}/poi/import`, data, config)
        .then((res) => {
          message.success(res.data.message);
          setModalUpload(false);
          dispatch(
            fetchPois({
              siteId,
              categoryId: selectedCategory,
              type: selectedTab,
            })
          );
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
          } else {
            message.error('Server is stopped');
          }
        })
        .finally(() => {
          setIsUploaded(false);
        });
    }
  };

  const handleSearch = (e) => {
    // setSearchError(null);
    // if (!regexInput(e.target.value) && e.target.value) {
    //   setSearchError('Search cannot using special character');
    // }
    setSearch(e.target.value);
  };

  const poisListSorted =
    sortBy.direction === 'desc'
      ? _.sortBy(poisList, sortBy.sort).reverse()
      : _.sortBy(poisList, sortBy.sort);

  const dataByFloor = poisListSorted.filter((poi) => {
    return selectedFloor !== 'all' ? poi.floorId === selectedFloor : true;
  });

  const dataByTag = dataByFloor.filter((poi) => {
    return selectedTag !== 'all' ? poi.tags.includes(selectedTag) : true;
  });
  const dataBySubcat = selectedSubCategory
    ? dataByTag.filter((poi) => {
        return selectedSubCategory !== 'all'
          ? poi.subCategoryIds.includes(selectedSubCategory)
          : true;
      })
    : dataByTag;

  const filteredData = search
    ? dataBySubcat.filter((el) => {
        const clonedElem = { ...el };
        return clonedElem.name.toLowerCase().includes(search.toLowerCase());
      })
    : dataBySubcat;

  const getCategory = (categoryId) => {
    const findCategory = categoriesList.find((cat) => cat._id === categoryId);
    return findCategory?.name;
  };

  const getSubCategory = (subCategoryId) => {
    const findCategory = subCategoryList.find(
      (cat) => cat._id === subCategoryId
    );
    return findCategory?.name;
  };

  const handleSetPlaylist = () => {
    setSelectedCategory(tempSelectedCategory);
    setPlaylistOpen(false);
  };

  const handleEdit = (id) => {
    history.push(`/${siteId}/information/${siteName}/pois/${id}`);
  };

  const getFloor = () => {
    const findFloor = floorList.find((floor) => floor._id === selectedFloor);
    return findFloor?.name;
  };
  const getTag = () => {
    const findTag = tagList.find((tag) => tag._id === selectedTag);
    return findTag?.name;
  };

  const handleSortTemp = (type, value) => {
    setSortByTemp({
      ...sortByTemp,
      [type]: value,
    });
  };

  const handleSort = () => {
    setSortBy(sortByTemp);
    setSortOpen(false);
  };

  const handleSetCategory = (data) => {
    dispatch(setCategory(data));
  };
  const handleSetSubCategory = (data) => {
    dispatch(setSubCategory(data));
  };

  const onSubmitCategory = () => {
    const action = category._id ? modifyCategory : createCategory;

    dispatch(
      action({ ...category, siteId, id: category._id, uid }, () =>
        setModalCategory(false)
      )
    );
  };
  const onSubmitSubCategory = () => {
    const action = subCategory._id ? modifySubCategory : createSubCategory;

    dispatch(
      action(
        {
          ...subCategory,
          siteId,
          categoryId: selectedCategory,
          id: subCategory._id,
          uid,
        },
        () => setModalSubCategory(false)
      )
    );
  };

  const handleEditCategory = (category) => {
    // setIsEditing(true);
    dispatch(setCategory(category, () => setModalCategory(true)));
  };

  const handleEditSubCategory = (subCat) => {
    dispatch(setSubCategory(subCat, () => setModalSubCategory(true)));
  };

  const handleDeleteCategory = (category) => {
    setModalDeleteCategory({
      show: true,
      category,
    });
  };
  const handleDeleteSubCategory = (subCat) => {
    console.log(subCat, 'sub cate');
    setModalDeleteSubCategory({
      show: true,
      subCategory: subCat,
    });
  };

  const onCloseModalCategoryHandler = () => {
    setModalDeleteCategory({
      show: false,
      category: null,
    });
  };
  const onCloseModalSubCategoryHandler = () => {
    setModalDeleteSubCategory({
      show: false,
      category: null,
    });
  };

  const onDeleteCategory = () => {
    dispatch(
      deleteCategory(modalDeleteCategory.category._id, () =>
        onCloseModalCategoryHandler()
      )
    );
  };
  const onDeleteSubCategory = () => {
    dispatch(
      deleteSubCategory(modalDeleteSubCategory.subCategory._id, () =>
        onCloseModalSubCategoryHandler()
      )
    );
  };

  const onSubmitFloor = () => {
    const action = floor._id ? modifyFloor : createFloor;

    dispatch(
      action({ uid, ...floor, siteId, id: floor._id }, () =>
        setModalFloor(false)
      )
    );
  };
  const onSubmitTag = (tagSaved) => {
    const action = tag._id ? modifyTag : createTag;

    console.log(tagSaved);
    dispatch(
      action({ uid, ...tagSaved, siteId, id: tag._id }, () => {
        setModalTag(false);
      })
    );
  };

  const handleSetFloor = (data) => {
    dispatch(setFloor(data));
  };
  const handleSetTag = (data) => {
    dispatch(setTag(data));
  };

  const handleSelectedSubCategory = () => {
    setSelectedSubCategory(tempSelectedSubCategory);
    setSubCategoryOpen(false);
  };
  const handleSetFloorCategory = () => {
    setSelectedFloor(tempSelectedFloor);
    setFloorOpen(false);
  };
  const handleSetTagCategory = () => {
    setSelectedTag(tempSelectedTag);
    setTagOpen(false);
  };

  const handleEditFloor = (floor) => {
    // setIsEditingFloor(true);
    dispatch(setFloor(floor));
    setModalFloor(true);
  };
  const handleEditTag = (tag) => {
    // setIsEditingFloor(true);
    dispatch(setTag(tag));
    setModalTag(true);
  };

  const handleDeleteFloor = (floor) => {
    setModalDeleteFloor({
      show: true,
      floor,
    });
  };
  const handleDeleteTag = (tag) => {
    setModalDeleteTag({
      show: true,
      tag,
    });
  };

  const onCloseModalFloorHandler = () => {
    setModalDeleteFloor({
      show: false,
      floor: null,
    });
  };
  const onCloseModalTagHandler = () => {
    setModalDeleteTag({
      show: false,
      tag: null,
    });
  };

  const onDeleteFloor = () => {
    dispatch(
      deleteFloor(modalDeleteFloor.floor._id, () => onCloseModalFloorHandler())
    );
  };
  const onDeleteTag = () => {
    dispatch(
      deleteTag({ id: modalDeleteTag.tag._id, siteId }, () =>
        onCloseModalTagHandler()
      )
    );
  };

  const handleCancel = () => {
    dispatch(
      setCategory(
        {
          _id: '',
          name: '',
          description: '',
          siteId: '',
          modifiedAt: new Date().toDateString(),
          modifiedBy: '',
        },
        () => setModalCategory(false)
      )
    );
  };
  const handleCancelSubCategory = () => {
    dispatch(
      setSubCategory(
        {
          _id: '',
          name: '',
          description: '',
          siteId: '',
          categoryId: '',
          modifiedAt: new Date().toDateString(),
          modifiedBy: '',
        },
        () => setModalSubCategory(false)
      )
    );
  };

  const handleNew = () => {
    dispatch(
      setCategory(
        {
          _id: '',
          name: '',
          description: '',
          siteId: '',
          modifiedAt: new Date().toDateString(),
          modifiedBy: '',
        },
        () => setModalCategory(true)
      )
    );
  };
  const handleNewSubCategory = () => {
    dispatch(
      setSubCategory(
        {
          _id: '',
          name: '',
          description: '',
          siteId: '',
          categoryId: '',
          modifiedAt: new Date().toDateString(),
          modifiedBy: '',
        },
        () => setModalSubCategory(true)
      )
    );
  };

  const handleNewFloor = () => {
    dispatch(
      setFloor(
        {
          _id: '',
          name: '',
          description: '',
        },
        () => setModalFloor(true)
      )
    );
  };
  const handleNewTag = () => {
    dispatch(
      setTag(
        {
          _id: '',
          name: '',
          logo: null,
          file: null,
        },
        () => setModalTag(true)
      )
    );
  };

  return (
    <div className="media-library">
      <ModalCategoryForm
        visible={modalCategory}
        onCancel={handleCancel}
        onCreate={onSubmitCategory}
        category={category}
        setCategory={handleSetCategory}
        isEditing={!!category._id}
      />
      <ModalSubCategoryForm
        visible={modalSubCategory}
        onCancel={handleCancelSubCategory}
        onCreate={onSubmitSubCategory}
        subCategory={subCategory}
        setSubCategory={handleSetSubCategory}
        isEditing={!!subCategory._id}
      />

      <ModalFloorForm
        visible={modalFloor}
        onCancel={() => {
          setModalFloor(false);
        }}
        onCreate={onSubmitFloor}
        floor={floor}
        setFloor={handleSetFloor}
        isEditing={!!floor._id}
      />
      <ModalTagForm
        visible={modalTag}
        onCancel={() => {
          setModalTag(false);
        }}
        onCreate={onSubmitTag}
        tag={tag}
        setTag={handleSetTag}
        isEditing={!!tag._id}
      />

      <ModalDelete
        isVisible={deleteModal.isOpen}
        onOk={onDeletePoiHandler}
        onCancel={onCloseModalHandler}
        title="Delete Tenant"
        subtitle={deleteModal.poi ? deleteModal.poi.name : ''}
        loading={loading}
      />

      <ModalDelete
        isVisible={modalDeleteCategory.show}
        onOk={onDeleteCategory}
        onCancel={onCloseModalCategoryHandler}
        title="Delete Category"
        subtitle={
          modalDeleteCategory.category ? modalDeleteCategory.category.name : ''
        }
        loading={categoryLoading}
      />

      <ModalDelete
        isVisible={modalDeleteSubCategory.show}
        onOk={onDeleteSubCategory}
        onCancel={onCloseModalSubCategoryHandler}
        title="Delete Sub Category"
        subtitle={
          modalDeleteSubCategory.subCategory
            ? modalDeleteSubCategory.subCategory.name
            : ''
        }
        loading={subCategoryLoading}
      />

      <ModalDelete
        isVisible={modalDeleteFloor.show}
        onOk={onDeleteFloor}
        onCancel={onCloseModalFloorHandler}
        title="Delete Floor"
        subtitle={modalDeleteFloor.floor ? modalDeleteFloor.floor.name : ''}
        loading={floorLoading}
      />
      <ModalDelete
        isVisible={modalDeleteTag.show}
        onOk={onDeleteTag}
        onCancel={onCloseModalTagHandler}
        title="Delete Tag"
        subtitle={modalDeleteTag.tag ? modalDeleteTag.tag.name : ''}
        loading={tagLoading}
      />

      <Modal
        footer={false}
        onCancel={() => {
          setModalUpload(false);
          setIsUploaded(false);
        }}
        visible={modalUpload}
        title="Import Tenant"
        className="modal-poi-upload adsign-modal"
        closeIcon={<i className="feather-x" />}
      >
        {isUploaded ? (
          <div className="upload-loading">
            <p>
              <i className="mdi mdi-loading mdi-spin" />
              Uploading...
            </p>
            <Progress percent={progress} />
          </div>
        ) : (
          <div className="upload-wrapper">
            <p>Upload</p>
            <Upload
              showUploadList={false}
              beforeUpload={onFileChangedHandler}
              style={{ width: '100%' }}
            >
              <div
                onDragLeave={() => setIsDragged(false)}
                onDragOver={() => setIsDragged(true)}
                className={`modal-drop upload-drag-and-drop  ${
                  isDragged ? 'active' : ''
                }`}
              >
                <ExportIcon size={22} color="#C2C2C2" />
                <p className="upload-title">
                  Drag or <span className="upload-highlight">Browse</span>
                </p>
                <p className="upload-subtitle">
                  File format supported: csv (Up to 50MB){' '}
                </p>
              </div>
            </Upload>
          </div>
        )}
      </Modal>

      <div className="media-header">
        <div className="media-header-left">
          <div className="searchbar-wrapper">
            <div className="searchbar">
              <i className="feather-search" />
              <input
                placeholder="Search"
                className="search-input"
                value={search}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="actions__wrapper">
          {/* <Button
            type="danger"
            className="btn btn-danger media__delete"
            onClick={onDeleteButtonHandler}
          >
            Delete Media
          </Button> */}

          <button className="btn-outline-primary" onClick={handleUpload}>
            <ImportIcon color="#64D5D2" size={18} />
            Import Tenant
          </button>

          <button className="btn-outline-primary" onClick={handleDownload}>
            <ExportIcon color="#64D5D2" size={18} />
            Export Tenant
          </button>
          {role !== 'superadmin' ? (
            access &&
            access.create && (
              <Link
                to={`/${siteId}/information/${siteName}/pois/new`}
                className="btn-primary"
              >
                <i className="feather-plus mr-2" />
                New Tenant
              </Link>
            )
          ) : (
            <Link
              to={`/${siteId}/information/${siteName}/pois/new`}
              className="btn-primary"
            >
              <i className="feather-plus mr-2" />
              New Tenant
            </Link>
          )}
        </div>
      </div>

      <div className="media-header">
        <div className="media-header-left">
          <div
            className="playlist-wrapper"
            onClick={() => setPlaylistOpen(!playlistOpen)}
          >
            <div className="playlist-selection">
              <ForwardIcon />
              <span>
                {selectedCategory === 'all'
                  ? 'All Categories'
                  : getCategory(selectedCategory)}
              </span>
            </div>
            <i
              className={`feather-chevron-down ${
                playlistOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={categoriesList}
              setTempSelectedCategory={(id) => setTempSelectedCategory(id)}
              handleEditCategory={(cat) => handleEditCategory(cat)}
              handleDeleteCategory={(cat) => handleDeleteCategory(cat)}
              modalOpen={playlistOpen}
              handleSetPlaylist={handleSetPlaylist}
              tempSelectedCategory={tempSelectedCategory}
              handleNew={handleNew}
              filter
            />
          </div>
          {selectedCategory !== 'all' && (
            <div
              className="playlist-wrapper"
              onClick={() => setSubCategoryOpen(!subCategoryOpen)}
            >
              <div className="playlist-selection">
                <ForwardIcon />
                <span>
                  {selectedSubCategory === 'all'
                    ? 'All Sub Categories'
                    : getSubCategory(selectedSubCategory)}
                </span>
              </div>
              <i
                className={`feather-chevron-down ${
                  subCategoryOpen ? 'playlist-open' : 'playlist-closed'
                }`}
              />

              <CategoryDropdown
                categories={subCategoryList.filter(
                  (sub) => sub.categoryId === selectedCategory
                )}
                setTempSelectedCategory={(id) => setTempSelectedSubCategory(id)}
                handleEditCategory={(cat) => handleEditSubCategory(cat)}
                handleDeleteCategory={(cat) => handleDeleteSubCategory(cat)}
                modalOpen={subCategoryOpen}
                title="Sub Category"
                handleSetPlaylist={handleSelectedSubCategory}
                tempSelectedCategory={tempSelectedSubCategory}
                handleNew={handleNewSubCategory}
                filter
              />
            </div>
          )}

          <div
            className="playlist-wrapper"
            onClick={() => setSortOpen(!sortOpen)}
          >
            <div className="playlist-selection">
              <SortIcon />
              <span>Sort By</span>
            </div>
            <i
              className={`feather-chevron-down ${
                sortOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <div
              className={`playlist-dropdown ${
                sortOpen ? 'playlist-dropdown-open' : 'playlist-dropdown-closed'
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              <ul>
                <li>
                  <div
                    className="radio"
                    onClick={() => handleSortTemp('sort', 'name')}
                  >
                    {sortByTemp.sort === 'name' && (
                      <div className="radio-checked" />
                    )}
                  </div>
                  Name
                </li>
                <li>
                  <div
                    className="radio"
                    onClick={() => handleSortTemp('sort', 'location')}
                  >
                    {sortByTemp.sort === 'location' && (
                      <div className="radio-checked" />
                    )}
                  </div>
                  Unit Number
                </li>
                <li className="divider" />
                <li>
                  <div
                    className="radio"
                    onClick={() => handleSortTemp('direction', 'asc')}
                  >
                    {sortByTemp.direction === 'asc' && (
                      <div className="radio-checked" />
                    )}
                  </div>
                  Ascending
                </li>
                <li>
                  <div
                    className="radio"
                    onClick={() => handleSortTemp('direction', 'desc')}
                  >
                    {sortByTemp.direction === 'desc' && (
                      <div className="radio-checked" />
                    )}
                  </div>
                  Descending
                </li>
                <li className="playlist-button" onClick={handleSort}>
                  Sort
                </li>
              </ul>
            </div>
          </div>

          <div
            className="playlist-wrapper"
            onClick={() => setFloorOpen(!floorOpen)}
          >
            <div className="playlist-selection">
              <LayerIcon />
              <span>
                {selectedFloor === 'all'
                  ? 'All Floor'
                  : getFloor(setSelectedFloor)}
              </span>
            </div>
            <i
              className={`feather-chevron-down ${
                floorOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={floorList}
              setTempSelectedCategory={(id) => setTempSelectedFloor(id)}
              handleEditCategory={(cat) => handleEditFloor(cat)}
              handleDeleteCategory={(cat) => handleDeleteFloor(cat)}
              modalOpen={floorOpen}
              handleSetPlaylist={handleSetFloorCategory}
              tempSelectedCategory={tempSelectedFloor}
              handleNew={handleNewFloor}
              filter
              title="Floor"
            />
          </div>
          <div
            className="playlist-wrapper"
            onClick={() => setTagOpen(!tagOpen)}
          >
            <div className="playlist-selection">
              <LayerIcon />
              <span>
                {selectedTag === 'all' ? 'All Tags' : getTag(setSelectedTag)}
              </span>
            </div>
            <i
              className={`feather-chevron-down ${
                tagOpen ? 'playlist-open' : 'playlist-closed'
              }`}
            />

            <CategoryDropdown
              categories={tagList}
              setTempSelectedCategory={(id) => setTempSelectedTag(id)}
              handleEditCategory={(cat) => handleEditTag(cat)}
              handleDeleteCategory={(cat) => handleDeleteTag(cat)}
              modalOpen={tagOpen}
              handleSetPlaylist={handleSetTagCategory}
              tempSelectedCategory={tempSelectedTag}
              handleNew={handleNewTag}
              filter
              title="Tag"
            />
          </div>
        </div>
      </div>
      <div className="media-content-wrapper">
        <div className="media-content">
          <div className="media-tab-wrapper">
            <div className="media-tab-left">
              <div
                onClick={() => dispatch(setTabTenant('published'))}
                className={`media-tab ${
                  selectedTab === 'published' ? 'active' : ''
                }`}
              >
                Active Tenants
              </div>
              <div
                onClick={() => dispatch(setTabTenant('unpublished'))}
                className={`media-tab ${
                  selectedTab === 'unpublished' ? 'active' : ''
                }`}
              >
                Unpublished Tenants
              </div>
              <div
                onClick={() => dispatch(setTabTenant('archived'))}
                className={`media-tab ${
                  selectedTab === 'archived' ? 'active' : ''
                }`}
              >
                Removed Tenants
              </div>
            </div>
          </div>
          <div className="media-list-content">
            {filteredData.length > 0 && filteredData.length > 0 ? (
              _.chunk(filteredData, 3).map((chunk, index) => {
                return (
                  <div className="media-list-chunk" key={index}>
                    {chunk.map((poi) => {
                      return (
                        <div
                          key={poi._id}
                          data-id={poi._id}
                          className="media-list"
                        >
                          <Cards
                            data={poi}
                            type="tenant"
                            isShowMedia
                            editAction={() => handleEdit(poi._id)}
                            deleteAction={() =>
                              onRemoveButtonClickHandler(poi._id)
                            }
                          />
                        </div>
                      );
                    })}
                    {chunk.length < 2 && <div className="media-list-empty" />}
                    {chunk.length < 3 && <div className="media-list-empty" />}
                  </div>
                );
              })
            ) : (
              <div className="media-empty">
                <Empty image={EmptyIcon} description="No Tenant" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pois;
