import Axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { url } from 'utils/url';
import { errorMsg } from 'utils';

export const TENANTS_FETCH_DATA_INIT = createAction('TENANTS_FETCH_DATA_INIT');
export const TENANTS_FETCH_DATA_SUCCESS = createAction(
  'TENANTS_FETCH_DATA_SUCCESS'
);
export const TENANTS_FETCH_DATA_FAIL = createAction('TENANTS_FETCH_DATA_FAIL');

export const TENANTS_DELETE_TENANT_INIT = createAction(
  'TENANTS_DELETE_TENANT_INIT'
);
export const TENANTS_DELETE_TENANT_SUCCESS = createAction(
  'TENANTS_DELETE_TENANT_SUCCESS'
);
export const TENANTS_DELETE_TENANT_FAIL = createAction(
  'TENANTS_DELETE_TENANT_FAIL'
);

export const TENANTS_CLEAR_DATA = createAction('TENANTS_CLEAR_DATA');

export const TENANTS_CREATE_TENANT_INIT = createAction(
  'TENANTS_CREATE_TENANT_INIT'
);
export const TENANTS_CREATE_TENANT_SUCCESS = createAction(
  'TENANTS_CREATE_TENANT_SUCCESS'
);
export const TENANTS_CREATE_TENANT_FAIL = createAction(
  'TENANTS_CREATE_TENANT_FAIL'
);

export const TENANTS_MODIFY_TENANT_INIT = createAction(
  'TENANTS_MODIFY_TENANT_INIT'
);
export const TENANTS_MODIFY_TENANT_SUCCESS = createAction(
  'TENANTS_MODIFY_TENANT_SUCCESS'
);
export const TENANTS_MODIFY_TENANT_FAIL = createAction(
  'TENANTS_MODIFY_TENANT_FAIL'
);

export const TENANTS_CLEAN_UP = createAction('TENANTS_CLEAN_UP');

export const TENANTS_CLEAR_DATA_LOGOUT = createAction(
  'TENANTS_CLEAR_DATA_LOGOUT'
);
export const TENANTS_SYNC_TENANT_INIT = createAction(
  'TENANTS_SYNC_TENANT_INIT'
);
export const TENANTS_SYNC_TENANT_SUCCESS = createAction(
  'TENANTS_SYNC_TENANT_SUCCESS'
);
export const TENANTS_SYNC_TENANT_FAIL = createAction(
  'TENANTS_SYNC_TENANT_FAIL'
);

export const fetchTenants = ({
  tenantId,
  siteId,
  categoryId = 'all',
  type = 'published',
}) => {
  // eslint-disable-next-line
  return async (dispatch, getState) => {
    dispatch(TENANTS_FETCH_DATA_INIT());

    if (siteId) {
      let tenants;
      try {
        tenants = await Axios.post(
          `${url}/tenant/filter`,
          {
            siteId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          }
        );
        console.log(tenants, 'tenants');
      } catch (error) {
        let errorMessage;
        if (error.response) {
          errorMessage = error && error.response && error.response.data.message;
        }
        toastr.error('', errorMessage);
        return dispatch(TENANTS_FETCH_DATA_FAIL({ error }));
      }
      const { data } = tenants;
      return dispatch(TENANTS_FETCH_DATA_SUCCESS(data));
    }
  };
};

export const removeTenant = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(TENANTS_DELETE_TENANT_INIT());
    try {
      await Axios.post(
        `${url}/tenant/remove`,
        {
          tenantId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TENANTS_DELETE_TENANT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The tenant was removed.');
    cb();
    return dispatch(TENANTS_DELETE_TENANT_SUCCESS({ id }));
  };
};

export const deleteTenant = ({ id, siteId }, cb) => {
  return async (dispatch, getState) => {
    dispatch(TENANTS_DELETE_TENANT_INIT());
    try {
      await Axios.post(
        `${url}/tenant/delete`,
        {
          tenantId: id,
          siteId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TENANTS_DELETE_TENANT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }

    toastr.success('', 'The tenant was deleted.');
    cb();
    return dispatch(TENANTS_DELETE_TENANT_SUCCESS({ id }));
  };
};

export const clearTenantsData = () => {
  return (dispatch) => {
    dispatch(TENANTS_CLEAR_DATA());
  };
};

export const clearTenantsDataLogout = () => {
  return (dispatch) => {
    dispatch(TENANTS_CLEAR_DATA_LOGOUT());
  };
};

export const createTenant = (data) => {
  return async (dispatch, getState) => {
    dispatch(TENANTS_CREATE_TENANT_INIT());

    // let createTenant;

    const tenant = new FormData();
    tenant.set('jcr:title', data['jcr:title']);
    if (data['jcr:description'])
      tenant.set('jcr:description', data['jcr:description']);
    tenant.set('siteId', data.siteId);
    if (data.storeVibe) tenant.set('storeVibe', data.storeVibe.join(','));
    tenant.set('marketingcategory', data.marketingcategory.join(','));
    if (data.cuisines) tenant.set('cuisines', data.cuisines.join(','));
    if (data.foodTypes) tenant.set('foodTypes', data.foodTypes.join(','));
    tenant.set('locations', data.locations);
    tenant.set(
      'acceptsCapitavoucher',
      data.acceptsCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptseCapitavoucher',
      data.acceptseCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptsDbsInstantEarn',
      data.acceptsDbsInstantEarn
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptsHalalCertified',
      data.acceptsHalalCertified
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptsCapitacard',
      data.acceptsCapitacard
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    if (data.chopelink) tenant.set('chopelink', data.chopelink);
    if (data.storephonenumber)
      tenant.set('storephonenumber', data.storephonenumber);
    if (data.storeemailaddress)
      tenant.set('storeemailaddress', data.storeemailaddress);
    if (data.starttime && data.endtime)
      tenant.set(
        'openinghours',
        JSON.stringify({ starttime: data.starttime, endtime: data.endtime })
      );
    if (data.websiteaddress) tenant.set('websiteaddress', data.websiteaddress);
    if (data.sortimportance) tenant.set('sortimportance', data.sortimportance);
    tenant.append('file', data.file);

    try {
      await Axios.post(`${url}/tenant/create`, tenant, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      // let errorMessage;
      // if (error.response) {
      //   errorMessage = error && error.response && error.response.data.message;
      // }

      toastr.error('', 'Please fill in all required fields');
      // toastr.error('', errorMessage);
      return dispatch(
        TENANTS_CREATE_TENANT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const tenantJson = JSON.stringify(Object.fromEntries(tenant));
    toastr.success('', 'Tenant created successfully');
    return dispatch(TENANTS_CREATE_TENANT_SUCCESS({ tenant: tenantJson }));
  };
};

export const modifyTenant = (data) => {
  return async (dispatch) => {
    dispatch(TENANTS_MODIFY_TENANT_INIT());

    const tenant = new FormData();
    tenant.set('id', data.id);
    tenant.set('jcr:title', data['jcr:title']);
    if (data['jcr:description'])
      tenant.set('jcr:description', data['jcr:description']);
    tenant.set('siteId', data.siteId);
    if (data.storeVibe) tenant.set('storeVibe', data.storeVibe.join(','));
    tenant.set('marketingcategory', data.marketingcategory.join(','));
    if (data.cuisines) tenant.set('cuisines', data.cuisines.join(','));
    if (data.foodTypes) tenant.set('foodTypes', data.foodTypes.join(','));
    tenant.set('locations', data.locations);
    tenant.set(
      'acceptsCapitavoucher',
      data.acceptsCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptseCapitavoucher',
      data.acceptseCapitavoucher
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptsDbsInstantEarn',
      data.acceptsDbsInstantEarn
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptsHalalCertified',
      data.acceptsHalalCertified
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    tenant.set(
      'acceptsCapitacard',
      data.acceptsCapitacard
        ? 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/yes'
        : 'capitalanddatabasemanagement:dropdown-tags/optionsyesno/no'
    );
    if (data.chopelink) tenant.set('chopelink', data.chopelink);
    if (data.storephonenumber)
      tenant.set('storephonenumber', data.storephonenumber);
    if (data.storeemailaddress)
      tenant.set('storeemailaddress', data.storeemailaddress);
    if (data.starttime && data.endtime)
      tenant.set(
        'openinghours',
        JSON.stringify({ starttime: data.starttime, endtime: data.endtime })
      );
    if (data.websiteaddress) tenant.set('websiteaddress', data.websiteaddress);
    if (data.sortimportance) tenant.set('sortimportance', data.sortimportance);
    if (data.thumbnail) tenant.set('thumbnail', data.thumbnail);
    tenant.append('file', data.file);

    try {
      await Axios.put(`${url}/tenant/edit`, tenant, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'content-type': 'multipart/form-data',
        },
      });
    } catch (error) {
      let errorMessage;
      if (error.response) {
        errorMessage = error && error.response && error.response.data.message;
      }
      toastr.error('', errorMessage);
      return dispatch(
        TENANTS_MODIFY_TENANT_FAIL({
          error: error && error.response && error.response.data,
        })
      );
    }
    const tenantJson = JSON.stringify(Object.fromEntries(tenant));
    toastr.success('', 'Tenant updated successfully');
    return dispatch(TENANTS_MODIFY_TENANT_SUCCESS({ tenantJson, id: data.id }));
  };
};

export const syncTenants = () => {
  return async (dispatch, getState) => {
    dispatch(TENANTS_SYNC_TENANT_INIT());
    Axios.get(`${url}/tenant/fetch`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => {
        return dispatch(TENANTS_SYNC_TENANT_SUCCESS());
      })
      .catch((error) => {
        toastr.error('', `${errorMsg(dispatch, error)}`);
        return dispatch(TENANTS_SYNC_TENANT_FAIL({ error }));
      });
  };
};

export const tenantsCleanUp = () => (dispatch) => dispatch(TENANTS_CLEAN_UP());
