import { createReducer } from 'redux-act';

import {
  TENANTS_FETCH_DATA_INIT,
  TENANTS_FETCH_DATA_SUCCESS,
  TENANTS_FETCH_DATA_FAIL,
  TENANTS_DELETE_TENANT_INIT,
  TENANTS_DELETE_TENANT_SUCCESS,
  TENANTS_DELETE_TENANT_FAIL,
  TENANTS_CLEAR_DATA,
  TENANTS_CREATE_TENANT_INIT,
  TENANTS_CREATE_TENANT_SUCCESS,
  TENANTS_CREATE_TENANT_FAIL,
  TENANTS_MODIFY_TENANT_INIT,
  TENANTS_MODIFY_TENANT_SUCCESS,
  TENANTS_MODIFY_TENANT_FAIL,
  TENANTS_CLEAN_UP,
  TENANTS_CLEAR_DATA_LOGOUT,
  TENANTS_SYNC_TENANT_INIT,
  TENANTS_SYNC_TENANT_SUCCESS,
  TENANTS_SYNC_TENANT_FAIL,
} from 'state/actions/tenants';

const initialState = {
  tenant: [],
  brandtenant: [],
  vibe: {},
  unitnumber: {},
  category: {},
  cuisines: {},
  foodType: {},

  loading: false,
  downloading: false,
  error: {
    errors: null,
    message: '',
  },
  success: false,
  deleted: false,
};

export const tenantsReducer = createReducer(
  {
    [TENANTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [TENANTS_FETCH_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      tenant: payload.tenant || [],
      vibe: payload.vibe || {},
      unitnumber: payload.unitnumber || {},
      category: payload.category || {},
      cuisines: payload.cuisines || {},
      foodType: payload.foodType || {},
      loading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [TENANTS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TENANTS_DELETE_TENANT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TENANTS_DELETE_TENANT_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      deleted: true,
    }),
    [TENANTS_DELETE_TENANT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TENANTS_CLEAR_DATA]: (state) => ({
      ...initialState,
      list: state.list,
    }),
    [TENANTS_CREATE_TENANT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TENANTS_CREATE_TENANT_SUCCESS]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [TENANTS_CREATE_TENANT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TENANTS_MODIFY_TENANT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TENANTS_MODIFY_TENANT_SUCCESS]: (state, payload) => ({
      ...state,
      list: !state.list
        ? []
        : state.list.map((elem) => {
            if (elem.id === payload.id) {
              return {
                name: payload.tenant.name,
                location: payload.tenant.location,
                id: payload.id,
                logoUrl: payload.tenant.logoUrl,
                createdAt: payload.tenant.createdAt,
                email: elem.email,
                phone: payload.tenant.phone,
                priority: payload.tenant.priority,
                description: payload.tenant.description,
                logo: null,
                opentime: payload.tenant.opentime,
                url: payload.tenant.url,
                category: payload.tenant.category,
                siteId: payload.tenant.site,
                screenId: payload.tenant.screenId,
                tag: payload.tenant.tag,
                floor: payload.tenant.floor,
                modifiedAt: new Date().toDateString(),
                modifiedBy: payload.tenant.modifiedBy,
              };
            }
            return elem;
          }),
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: true,
    }),
    [TENANTS_MODIFY_TENANT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TENANTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: {
        errors: null,
        message: '',
      },
      success: false,
      deleted: false,
    }),
    [TENANTS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
    [TENANTS_SYNC_TENANT_INIT]: (state) => ({
      ...state,
      downloading: true,
    }),
    [TENANTS_SYNC_TENANT_SUCCESS]: (state) => ({
      ...state,
      downloading: false,
      error: {
        errors: null,
        message: '',
      },
    }),
    [TENANTS_SYNC_TENANT_FAIL]: (state, payload) => ({
      ...state,
      downloading: false,
      error: payload.error,
    }),
  },
  initialState
);
