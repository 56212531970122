import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { authReducer } from './auth';
import { usersReducer } from './users';
import { organizationsReducer } from './organizations';
import { sitesReducer } from './sites';
import { screensReducer } from './screens';
import { configsReducer } from './configuration';
import { filesReducer } from './files';
import { poisReducer } from './pois';
import { categoriesReducer } from './categories';
import { mediaCategoriesReducer } from './mediaCategories';
import { mediaReducer } from './media';
import { preferencesReducer } from './preferences';
import { mapsReducer } from './maps';
import { mapsStaticReducer } from './mapsStatic';
import { feedbackReducer } from './feedback';
import { respondentReducer } from './respondent';
import { screenshotReducer } from './screenshot';
import { informationReducer } from './information';
import { informationCategoriesReducer } from './informationCategories';
import { logsReducer } from './log';
import { tabReducer } from './tab';
// import { configReducer } from './config';
import { settingsReducer } from './settings';
import { notificationReducer } from './notification';
import { floorReducer } from './floors';
import { playlistReducer } from './playlist';
import { roleReducer } from './roles';
import { panelReducer } from './panel';
import { menuReducer } from './menu';
import { screenusersReducer } from './screenusers';
import { breadcrumbReducer } from './breadcrumb';
import { mapviewerReducer } from './mapviewer';
import { analyticReducer } from './analytic';
import { eventReducer } from './event';
import { dealReducer } from './deal';
import { directoriesReducer } from './directories';
import { syncedCategoriesReducer } from './syncedCategories';
import { overviewReducer } from './overview';
import { tenantsReducer } from './tenants';
import { tagReducer } from './tags';
import { subCategoriesReducer } from './subCategories';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  organizations: persistReducer(
    {
      key: 'organizations',
      storage,
      blacklist: ['error', 'loading'],
    },
    organizationsReducer
  ),
  sites: persistReducer(
    {
      key: 'sites',
      storage,
      blacklist: ['error', 'loading'],
    },
    sitesReducer
  ),
  screens: persistReducer(
    {
      key: 'screens',
      storage,
      blacklist: ['error', 'warning'],
    },
    screensReducer
  ),
  files: persistReducer(
    {
      key: 'files',
      storage,
      blacklist: ['error', 'warning'],
    },
    filesReducer
  ),
  pois: persistReducer(
    {
      key: 'pois',
      storage,
      blacklist: ['error', 'loading'],
    },
    poisReducer
  ),
  tenants: persistReducer(
    {
      key: 'pois',
      storage,
      blacklist: ['error', 'loading'],
    },
    tenantsReducer
  ),
  events: persistReducer(
    {
      key: 'events',
      storage,
      blacklist: ['error', 'loading'],
    },
    eventReducer
  ),
  deals: persistReducer(
    {
      key: 'deals',
      storage,
      blacklist: ['error', 'loading'],
    },
    dealReducer
  ),
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: ['error', 'loading', 'category'],
    },
    categoriesReducer
  ),
  mediaCategories: persistReducer(
    {
      key: 'mediaCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    mediaCategoriesReducer
  ),
  subcategories: persistReducer(
    {
      key: 'subcategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    subCategoriesReducer
  ),
  tags: persistReducer(
    {
      key: 'tags',
      storage,
      blacklist: ['error', 'loading'],
    },
    tagReducer
  ),
  syncedCategories: persistReducer(
    {
      key: 'syncedCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    syncedCategoriesReducer
  ),
  media: persistReducer(
    {
      key: 'media',
      storage,
      blacklist: ['error', 'loading', 'media'],
    },
    mediaReducer
  ),
  maps: persistReducer(
    {
      key: 'maps',
      storage,
      blacklist: ['error', 'loading'],
    },
    mapsReducer
  ),
  mapsStatic: persistReducer(
    {
      key: 'mapStatic',
      storage,
      blacklist: ['error', 'loading'],
    },
    mapsStaticReducer
  ),
  feedback: persistReducer(
    {
      key: 'feedback',
      storage,
      blacklist: ['error', 'loading'],
    },
    feedbackReducer
  ),
  floor: persistReducer(
    {
      key: 'floor',
      storage,
      blacklist: ['error', 'loading'],
    },
    floorReducer
  ),
  respondent: persistReducer(
    {
      key: 'respondent',
      storage,
      blacklist: ['error', 'loading'],
    },
    respondentReducer
  ),
  screenshot: persistReducer(
    {
      key: 'screenshot',
      storage,
      blacklist: ['error', 'loading'],
    },
    screenshotReducer
  ),
  information: persistReducer(
    {
      key: 'information',
      storage,
      blacklist: ['error', 'loading', 'information'],
    },
    informationReducer
  ),
  informationCategories: persistReducer(
    {
      key: 'informationCategories',
      storage,
      blacklist: ['error', 'loading', 'category'],
    },
    informationCategoriesReducer
  ),
  config: persistReducer(
    {
      key: 'config',
      storage,
      blacklist: ['error', 'loading'],
    },
    configsReducer
  ),
  settings: persistReducer(
    {
      key: 'settings',
      storage,
      blacklist: ['error', 'loading'],
    },
    settingsReducer
  ),
  playlist: persistReducer(
    {
      key: 'playlist',
      storage,
      blacklist: ['error', 'loading'],
    },
    playlistReducer
  ),
  logs: persistReducer(
    {
      key: 'logs',
      storage,
      blacklist: ['error', 'loading'],
    },
    logsReducer
  ),
  roles: persistReducer(
    {
      key: 'roles',
      storage,
      blacklist: ['error', 'loading'],
    },
    roleReducer
  ),
  notifications: persistReducer(
    {
      key: 'notifications',
      storage,
      blacklist: ['error', 'loading'],
    },
    notificationReducer
  ),
  panel: persistReducer(
    {
      key: 'panel',
      storage,
      blacklist: ['error', 'loading'],
    },
    panelReducer
  ),
  toastr: toastrReducer,
  tab: tabReducer,
  breadcrumb: breadcrumbReducer,
  menu: persistReducer(
    {
      key: 'menu',
      storage,
    },
    menuReducer
  ),
  screenusers: persistReducer(
    {
      key: 'screenusers',
      storage,
      blacklist: ['error', 'loading'],
    },
    screenusersReducer
  ),
  overview: persistReducer(
    {
      key: 'overview',
      storage,
      blacklist: ['error', 'loading'],
    },
    overviewReducer
  ),
  mapviewer: persistReducer(
    {
      key: 'mapviewer',
      storage,
      blacklist: ['error', 'loading'],
    },
    mapviewerReducer
  ),
  analytic: persistReducer(
    {
      key: 'analytic',
      storage,
      blacklist: ['error', 'loading'],
    },
    analyticReducer
  ),
  directories: persistReducer(
    {
      key: 'directories',
      storage,
      blacklist: ['error', 'loading'],
    },
    directoriesReducer
  ),
});
