/* eslint-disable jsx-a11y/label-has-associated-control */
import { Checkbox, Input, Select, Tooltip, Upload } from 'antd';
import { useChangeHandler } from 'hooks';
import React, { useEffect, useState } from 'react';
// import InputMask from 'react-input-mask';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { regexDescription, regexInput } from 'utils/regex';
import { imgUrl } from 'utils/url';
import { log } from 'utils';
import { ExportIcon } from 'assets/icon/dynamic';
// import { fetchPois, poisCleanUp } from 'state/actions/pois';
import { fetchTenants, tenantsCleanUp } from 'state/actions/tenants';
import { isEmpty } from 'underscore';
import './TenantForm.css';

const TenantForm = ({
  tenant,
  setTenant,
  success,
  action,
  id,
  history,
  error,
}) => {
  const { siteId, siteName } = useParams();
  log(error);

  const [isDragged, setIsDragged] = useState(false);
  const [errors, setErrors] = useState(error);
  const [categoriesPath, setCategoriesPath] = useState([]);
  const [vibePath, setVibePath] = useState([]);
  const [cuisinePath, setCuisinePath] = useState([]);
  const [foodTypePath, setFoodTypePath] = useState([]);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  const { loading, userData, cuisines, foodType, categories, vibe } =
    useSelector(
      (state) => ({
        loading: state.tenants.loading,
        userData: state.auth.userData,
        cuisines: state.tenants.cuisines,
        foodType: state.tenants.foodType,
        categories: state.tenants.category,
        poiList: state.tenants.tenant,
        vibe: state.tenants.vibe,
        category: state.categories.category,
        floor: state.floor.floor,
      }),
      shallowEqual
    );

  const uid = userData.id;

  const dispatch = useDispatch();

  const changeCategories = (obj, setPath) => {
    const paths = [];

    const checkNestedObject = (obj) => {
      for (const key in obj) {
        // console.log(obj[key], 'object key')
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          return true;
        }
      }
      return false;
    };

    const getValue = (obj) => {
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          const newObj = value;
          if (!obj.path) {
            newObj.path = `capitalanddatabasemanagement:sg/tenant-categories/${key}`;
            if (checkNestedObject(value)) {
              getValue(newObj);
            } else {
              paths.push({ path: newObj.path, name: value['jcr:title'] });
            }
          } else {
            newObj.path = `${obj.path}/${key}`;
            if (checkNestedObject(value)) {
              getValue(newObj);
            } else {
              paths.push({ path: newObj.path, name: value['jcr:title'] });
            }
          }
        }
      }
    };
    getValue(obj);
    setPath(paths);
  };

  useEffect(() => {
    if (success) {
      history.push(`/${siteId}/information/${siteName}`);
    }
  }, [success]); // eslint-disable-line
  useEffect(() => {
    dispatch(fetchTenants({ siteId }));
  }, []); // eslint-disable-line
  useEffect(() => {
    if (!isEmpty(categories)) changeCategories(categories, setCategoriesPath);
  }, [categories]); // eslint-disable-line
  useEffect(() => {
    if (!isEmpty(vibe)) changeCategories(vibe, setVibePath);
  }, [vibe]); // eslint-disable-line
  useEffect(() => {
    if (!isEmpty(cuisines)) changeCategories(cuisines, setCuisinePath);
  }, [cuisines]); // eslint-disable-line
  useEffect(() => {
    if (!isEmpty(foodType)) changeCategories(foodType, setFoodTypePath);
  }, [foodType]); // eslint-disable-line

  useEffect(() => {
    return () => dispatch(tenantsCleanUp());
  }, [dispatch]);

  const onChangeHandler = useChangeHandler(setTenant);

  const onFileChangedHandler = (file) => {
    setTenant((prevState) => ({ ...prevState, file, thumbnail: null }));
  };

  const handleDeletePreviewImage = () => {
    setTenant((prevState) => ({
      ...prevState,
      file: null,
      thumbnail: null,
    }));
  };

  const onSubmitHandler = (value) => {
    log(tenant);
    value.preventDefault();
    if (
      (!regexInput(tenant['jcr:title']) && tenant['jcr:title']) ||
      (!regexDescription(tenant['jcr:description']) &&
        tenant['jcr:description']) ||
      (tenant.marketingcategory && tenant.marketingcategory.length === 0) ||
      !tenant.locations
    ) {
      const newErrors = {
        ...errors,
        errors: {
          ...errors.errors,
        },
        message: 'Something went wrong!',
      };

      newErrors.errors.name = !regexInput(tenant.name) &&
        tenant.name && [
          {
            msg: 'This field cannot contain any special characters',
          },
        ];

      newErrors.errors.description = !regexDescription(tenant.description) &&
        tenant.description && [
          { msg: 'This field cannot contain any special characters' },
        ];
      newErrors.errors.marketingcategory = tenant.marketingcategory.length ===
        0 &&
        tenant.marketingcategory && [{ msg: 'This field is required' }];
      newErrors.errors.locations = !tenant.locations && [
        { msg: 'This field is required' },
      ];

      setErrors(newErrors);
    } else {
      log(tenant, 'tenant');
      dispatch(
        action({
          uid,
          ...tenant,
          siteId,
          id,
        })
      );
    }
  };

  // const canSubmit = tenant.name && tenant.description;

  const imagePreviewUrl = tenant.file
    ? tenant.file && URL.createObjectURL(tenant.file)
    : tenant.thumbnail && `${imgUrl}/${tenant.thumbnail}`;

  // const goBackMessage = useFormatMessage('TenantForm.goBack');

  // const pickAnotherFileMessage = useFormatMessage('TenantForm.pickAnotherFile');
  // const pickFileMessage = useFormatMessage('TenantForm.pickFile');

  return (
    <div className="tenant-form">
      <form className="form-wrapper">
        <div>
          <div className="form">
            <label className="form-label">
              Name <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Tenant Name"
              className={`${
                errors && errors.errors && errors.errors.name ? 'has-error' : ''
              }`}
              name="jcr:title"
              value={tenant['jcr:title']}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.name && (
              <span className="error-message">{errors.errors.name[0].msg}</span>
            )}
          </div>

          <div className="form">
            <label className="form-label">
              Marketing Category <span className="is-required">*</span>
            </label>
            <Select
              mode="multiple"
              options={categoriesPath.map((path) => ({
                value: path.path,
                label: path.name,
              }))}
              value={tenant.marketingcategory}
              onChange={(e) => setTenant({ ...tenant, marketingcategory: e })}
            />
          </div>
          <div className="form">
            <label className="form-label">Store Vibe</label>
            <Select
              mode="multiple"
              options={vibePath.map((vibe) => ({
                value: vibe.path,
                label: vibe.name,
              }))}
              value={tenant.storeVibe}
              onChange={(e) => setTenant({ ...tenant, storeVibe: e })}
            />
          </div>
          <div className="form">
            <label className="form-label">Store Cuisine</label>
            <Select
              mode="multiple"
              options={cuisinePath.map((vibe) => ({
                value: vibe.path,
                label: vibe.name,
              }))}
              value={tenant.cuisines}
              onChange={(e) => setTenant({ ...tenant, cuisines: e })}
            />
          </div>
          <div className="form">
            <label className="form-label">Food Type</label>
            <Select
              mode="multiple"
              options={foodTypePath.map((vibe) => ({
                value: vibe.path,
                label: vibe.name,
              }))}
              value={tenant.foodTypes}
              onChange={(e) => setTenant({ ...tenant, foodTypes: e })}
            />
          </div>
          <div className="form">
            <label className="form-label">
              {' '}
              Location <span className="is-required">*</span>
            </label>
            <Input
              placeholder="Location"
              className={`${
                errors && errors.errors && errors.errors.locations
                  ? 'has-error'
                  : ''
              }`}
              name="locations"
              value={tenant.locations}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.sortimportance && (
              <span className="error-message">
                {errors.errors.sortimportance[0].msg}
              </span>
            )}
          </div>

          <div className="form">
            <label className="form-label">Description</label>
            <Input.TextArea
              rows={5}
              placeholder="Tenant Description"
              className={`${
                errors && errors.errors && errors.errors.description
                  ? 'has-error'
                  : ''
              }`}
              name="jcr:description"
              value={tenant['jcr:description']}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.description && (
              <span className="error-message">
                {errors.errors.description[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Accept Capita Voucher</label>
            <Checkbox
              placeholder="Tenant Description"
              name="acceptsCapitavoucher"
              checked={tenant.acceptsCapitavoucher}
              onChange={onChangeHandler}
            >
              Accept Capita Voucher
            </Checkbox>
          </div>
          <div className="form">
            <label className="form-label">Accept eCapita Voucher</label>
            <Checkbox
              placeholder="Tenant Description"
              name="acceptseCapitavoucher"
              checked={tenant.acceptseCapitavoucher}
              onChange={onChangeHandler}
            >
              Accept eCapita Voucher
            </Checkbox>
          </div>
          <div className="form">
            <label className="form-label">Accept Capita Card</label>
            <Checkbox
              placeholder="Tenant Description"
              name="acceptsCapitaCard"
              checked={tenant.acceptsCapitaCard}
              onChange={onChangeHandler}
            >
              Accept Capita Card
            </Checkbox>
          </div>
          <div className="form">
            <label className="form-label">Accept DBS Instant Earn</label>
            <Checkbox
              placeholder="Tenant Description"
              name="acceptsDbsInstantEarn"
              checked={tenant.acceptsDbsInstantEarn}
              onChange={onChangeHandler}
            >
              Accept DBS Instant Earn
            </Checkbox>
          </div>
          <div className="form">
            <label className="form-label">Accept Halal Certified</label>
            <Checkbox
              placeholder="Tenant Description"
              name="acceptsHalalCertified"
              checked={tenant.acceptsHalalCertified}
              onChange={onChangeHandler}
            >
              Accept Halal Certified
            </Checkbox>
          </div>
        </div>
        <div>
          <div className="form">
            <label className="form-label">Image</label>
            {imagePreviewUrl ? (
              <div className="upload-image-preview">
                <Tooltip placement="top" title="Delete">
                  <button
                    type="button"
                    onClick={handleDeletePreviewImage}
                    className="btn-remove"
                  >
                    <i className="feather-x" />
                  </button>
                </Tooltip>
                <img
                  className="media-avatar"
                  src={imagePreviewUrl}
                  alt="User profile logo preview"
                />
              </div>
            ) : (
              <Upload
                accept="image/*"
                showUploadList={false}
                beforeUpload={onFileChangedHandler}
              >
                <div
                  onDragLeave={() => setIsDragged(false)}
                  onDragOver={() => setIsDragged(true)}
                  className={`upload-drag-and-drop ${
                    isDragged ? 'active' : ''
                  }`}
                >
                  <ExportIcon size={22} color="#C2C2C2" />
                  <p className="upload-title">
                    Drag or <span className="upload-highlight">Browse</span>
                  </p>
                  <p className="upload-subtitle">
                    File format supported: jpg, png (Up to 5MB){' '}
                  </p>
                </div>
              </Upload>
            )}
          </div>

          {/* <div className="form">
            <label className="form-label">Scheduling:</label>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              className="datepicker-adsign"
              format="DD/MM/YYYY HH:mm"
              showTime
              onChange={handleChangeDate}
              value={
                tenant.start && tenant.end
                  ? [dayjs(tenant.start), dayjs(tenant.end)]
                  : undefined
              }
            />
          </div> */}
          <div className="form">
            <label className="form-label">Opening Hours</label>
            <div className="input-row">
              <Input
                placeholder="XX:XX AM/PM"
                className={`${
                  errors && errors.errors && errors.errors.starttime
                    ? 'has-error'
                    : ''
                }`}
                name="starttime"
                value={tenant.starttime}
                onChange={onChangeHandler}
              />
              -
              <Input
                placeholder="XX:XX AM/PM"
                className={`${
                  errors && errors.errors && errors.errors.endtime
                    ? 'has-error'
                    : ''
                }`}
                name="endtime"
                value={tenant.endtime}
                onChange={onChangeHandler}
              />
            </div>
            {errors &&
              errors.errors &&
              (errors.errors.starttime || errors.errors.endtime) && (
                <span className="error-message">
                  {errors.errors.starttime[0]?.msg ??
                    errors.errors.endtime[0]?.msg}
                </span>
              )}
          </div>
          <div className="form">
            <label className="form-label"> Website Address</label>
            <Input
              placeholder="Tenant Website Address"
              className={`${
                errors && errors.errors && errors.errors.sortimportance
                  ? 'has-error'
                  : ''
              }`}
              name="websiteaddress"
              value={tenant.websiteaddress}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.websiteaddress && (
              <span className="error-message">
                {errors.errors.websiteaddress[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Tenant Phone Number</label>
            <Input
              placeholder="Tenant Phone Number"
              className={`${
                errors && errors.errors && errors.errors.storephonenumber
                  ? 'has-error'
                  : ''
              }`}
              name="storephonenumber"
              value={tenant.storephonenumber}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.websiteaddress && (
              <span className="error-message">
                {errors.errors.websiteaddress[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Tenant Email Address</label>
            <Input
              placeholder="Tenant Phone Number"
              className={`${
                errors && errors.errors && errors.errors.storeemailaddress
                  ? 'has-error'
                  : ''
              }`}
              name="storeemailaddress"
              value={tenant.storeemailaddress}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.storeemailaddress && (
              <span className="error-message">
                {errors.errors.storeemailaddress[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label">Chopelink</label>
            <Input
              placeholder="Tenant Chope Link"
              className={`${
                errors && errors.errors && errors.errors.chopelink
                  ? 'has-error'
                  : ''
              }`}
              name="chopelink"
              value={tenant.chopelink}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.chopelink && (
              <span className="error-message">
                {errors.errors.chopelink[0].msg}
              </span>
            )}
          </div>
          <div className="form">
            <label className="form-label"> Tenant Sort Importance</label>
            <Input
              placeholder="Tenant Sort Importance"
              className={`${
                errors && errors.errors && errors.errors.sortimportance
                  ? 'has-error'
                  : ''
              }`}
              name="sortimportance"
              value={tenant.sortimportance}
              onChange={onChangeHandler}
            />
            {errors && errors.errors && errors.errors.sortimportance && (
              <span className="error-message">
                {errors.errors.sortimportance[0].msg}
              </span>
            )}
          </div>
        </div>
      </form>
      <div
        className="button-wrapper"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div>
          {loading ? (
            <button className="button-primary" disabled>
              Loading...
            </button>
          ) : (
            <button className="btn-primary" onClick={onSubmitHandler}>
              Submit
            </button>
          )}
          {/* <button className="button-secondary" onClick={handleReset}>
            Reset
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default TenantForm;
