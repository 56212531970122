import { format } from 'date-fns';

export const formatDate = (string) => {
  return format(
    new Date(
      string.substring(0, 4),
      string.substring(5, 7),
      string.substring(8, 10)
    ),
    'yyyyMMdd'
  );
};

export const optionspie = {
  layout: {
    padding: {
      left: 15,
      right: 15,
      top: 15,
      bottom: 25,
    },
  },
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      display: true,
      color: 'black',
      font: {
        weight: 'bold',
      },
      formatter: (value, ctx) => {
        console.log(ctx, 'ctx formater');
        const total = ctx.chart.getDatasetMeta(0);
        const percentage = `${((value * 100) / total.total).toFixed(1)}%`;
        return percentage;
      },
    },
  },
};

export const transformToDate = (string) => {
  return new Date(
    string.substring(0, 4),
    string.substring(5, 7),
    string.substring(8, 10)
  );
};

export const legendMargin = {
  id: 'legendMargin',
  beforeInit(chart) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return (this.height += 40);
    };
  },
};

export const filterAccess = ({ userData, siteId, param, action }) => {
  if (userData.role === 'superadmin') {
    return true;
  }
  const acc = userData.roleAccess
    ? userData.roleAccess.access.find(
        (userAccess) => userAccess.siteId === siteId
      )
    : userData.access.find((userAccess) => userAccess.siteId === siteId) ??
      userData.access[0];
  if (acc) {
    return acc[param][action];
  }
  return false;
};
