import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { createAction } from 'redux-act';
import { errorMsg } from 'utils';
import { url } from 'utils/url';

export const OVERVIEW_FETCH_SITES_INIT = createAction(
  'OVERVIEW_FETCH_SITES_INIT'
);
export const OVERVIEW_FETCH_SITES_SUCCESS = createAction(
  'OVERVIEW_FETCH_SITES_SUCCESS'
);
export const OVERVIEW_FETCH_SITES_FAIL = createAction(
  'OVERVIEW_FETCH_SITES_FAIL'
);
export const OVERVIEW_FETCH_WEEKLY_INIT = createAction(
  'OVERVIEW_FETCH_WEEKLY_INIT'
);
export const OVERVIEW_FETCH_WEEKLY_SUCCESS = createAction(
  'OVERVIEW_FETCH_WEEKLY_SUCCESS'
);
export const OVERVIEW_FETCH_WEEKLY_FAIL = createAction(
  'OVERVIEW_FETCH_WEEKLY_FAIL'
);
export const OVERVIEW_FETCH_HOURLY_INIT = createAction(
  'OVERVIEW_FETCH_HOURLY_INIT'
);
export const OVERVIEW_FETCH_HOURLY_SUCCESS = createAction(
  'OVERVIEW_FETCH_HOURLY_SUCCESS'
);
export const OVERVIEW_FETCH_HOURLY_FAIL = createAction(
  'OVERVIEW_FETCH_HOURLY_FAIL'
);

export const OVERVIEW_FETCH_POI_INIT = createAction('OVERVIEW_FETCH_POI_INIT');
export const OVERVIEW_FETCH_POI_SUCCESS = createAction(
  'OVERVIEW_FETCH_POI_SUCCESS'
);
export const OVERVIEW_FETCH_POI_FAIL = createAction('OVERVIEW_FETCH_POI_FAIL');
export const OVERVIEW_FETCH_CATEGORY_INIT = createAction(
  'OVERVIEW_FETCH_CATEGORY_INIT'
);
export const OVERVIEW_FETCH_CATEGORY_SUCCESS = createAction(
  'OVERVIEW_FETCH_CATEGORY_SUCCESS'
);
export const OVERVIEW_FETCH_CATEGORY_FAIL = createAction(
  'OVERVIEW_FETCH_CATEGORY_FAIL'
);
export const OVERVIEW_FETCH_KIOSK_INIT = createAction(
  'OVERVIEW_FETCH_KIOSK_INIT'
);
export const OVERVIEW_FETCH_KIOSK_SUCCESS = createAction(
  'OVERVIEW_FETCH_KIOSK_SUCCESS'
);
export const OVERVIEW_FETCH_KIOSK_FAIL = createAction(
  'OVERVIEW_FETCH_KIOSK_FAIL'
);
export const OVERVIEW_CHANGE_DATE = createAction('OVERVIEW_CHANGE_DATE');

export const fetchTopSites = ({ start, end }) => {
  return async (dispatch) => {
    dispatch(OVERVIEW_FETCH_SITES_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/topSites`,
        {
          start,
          end,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(OVERVIEW_FETCH_SITES_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      OVERVIEW_FETCH_SITES_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};
export const fetchTopPoi = ({ start, end }) => {
  return async (dispatch) => {
    dispatch(OVERVIEW_FETCH_POI_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/topType`,
        {
          start,
          end,
          type: 'poi',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(OVERVIEW_FETCH_POI_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      OVERVIEW_FETCH_POI_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};
export const fetchTopCategory = ({ start, end }) => {
  return async (dispatch) => {
    dispatch(OVERVIEW_FETCH_CATEGORY_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/topType`,
        {
          start,
          end,
          type: 'category',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(OVERVIEW_FETCH_CATEGORY_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      OVERVIEW_FETCH_CATEGORY_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};
export const fetchTopKiosk = ({ start, end }) => {
  return async (dispatch) => {
    dispatch(OVERVIEW_FETCH_KIOSK_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/topKiosk`,
        {
          start,
          end,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(OVERVIEW_FETCH_KIOSK_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      OVERVIEW_FETCH_KIOSK_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};
export const fetchHourlyData = ({ start, end }) => {
  return async (dispatch) => {
    dispatch(OVERVIEW_FETCH_HOURLY_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/hourly`,
        {
          start,
          end,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(OVERVIEW_FETCH_HOURLY_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      OVERVIEW_FETCH_HOURLY_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};
export const fetchWeeklyData = ({ start, end }) => {
  return async (dispatch) => {
    dispatch(OVERVIEW_FETCH_WEEKLY_INIT());
    let analytic;
    try {
      analytic = await axios.post(
        `${url}/analytic/weekly`,
        {
          start,
          end,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toastr.error('', `${errorMsg(dispatch, error)}`);
      return dispatch(OVERVIEW_FETCH_WEEKLY_FAIL({ error }));
    }

    // dispatch(calculateData({ data: analytic.data.rawData }));
    return dispatch(
      OVERVIEW_FETCH_WEEKLY_SUCCESS({
        data: analytic.data.data,
      })
    );
  };
};
export const changeDate = (dateRange) => {
  return async (dispatch) => {
    return dispatch(
      OVERVIEW_CHANGE_DATE({
        data: dateRange,
      })
    );
  };
};

// export const downloadAnalytics = (body) => {
//   return async (dispatch) => {
//     dispatch(OVERVIEW_EXPORT_ANALYTIC_INIT());
//     if (body.type === 'leaderboard') {
//       axios
//         .post(`${url}/analytic/exportTop`, body, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//           },
//           responseType: 'blob',
//         })
//         .then((res) => {
//           fileDownload(
//             res.data,
//             `logs_export_${body.type}_${moment(new Date()).format(
//               'YYYY-MM-DD'
//             )}.csv`
//           );
//           return dispatch(OVERVIEW_EXPORT_ANALYTIC_SUCCESS());
//         })
//         .catch((error) => {
//           toastr.error('', `${errorMsg(dispatch, error)}`);
//           return dispatch(OVERVIEW_EXPORT_ANALYTIC_FAIL({ error }));
//         });
//     } else {
//       axios
//         .post(`${url}/analytic/exportAll`, body, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//           },
//           responseType: 'blob',
//         })
//         .then((res) => {
//           fileDownload(
//             res.data,
//             `logs_export_rawdata_${moment(new Date()).format('YYYY-MM-DD')}.csv`
//           );
//           return dispatch(OVERVIEW_EXPORT_ANALYTIC_SUCCESS());
//         })
//         .catch((error) => {
//           toastr.error('', `${errorMsg(dispatch, error)}`);
//           return dispatch(OVERVIEW_EXPORT_ANALYTIC_FAIL({ error }));
//         });
//     }
//   };
// };
// export const downloadAnalyticsLeaderboard = (body) => {
//   return async (dispatch) => {
//     dispatch(OVERVIEW_EXPORT_ANALYTIC_INIT());
//     console.log(body, 'body');

//     axios
//       .post(`${url}/analytic/export`, body, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
//         },
//         responseType: 'blob',
//       })
//       .then((res) => {
//         fileDownload(
//           res.data,
//           `logs_export_${body.type}_${moment(new Date()).format(
//             'YYYY-MM-DD'
//           )}.csv`
//         );
//         return dispatch(OVERVIEW_EXPORT_ANALYTIC_SUCCESS());
//       })
//       .catch((error) => {
//         toastr.error('', `${errorMsg(dispatch, error)}`);
//         return dispatch(OVERVIEW_EXPORT_ANALYTIC_FAIL({ error }));
//       });
//   };
// };

// export const loadingAnalytic = () => {
//   return async (dispatch) => {
//     return dispatch(OVERVIEW_FETCH_DATA_INIT());
//   };
// };
